import { FC, useEffect, useRef } from 'react';
import { colorByPercentage } from 'src/constants/history';
import { uuid } from 'src/utils/utilities';
import CircularProgressBar from './circular';

const CircularProgress: FC<{ size?: number; percent: number; fontSize?: string }> = ({
  size = 160,
  percent = 0,
  fontSize = '16px',
}) => {
  const id = uuid();
  const isInit = useRef(false);

  useEffect(() => {
    if (!isInit.current) {
      const globalConfig = {
        fontSize,
        speed: 500,
        animationSmooth: '1s ease-out',
        strokeBottom: 4,
        stroke: 8,
        colorCircle: '#fff',
        round: true,
        size,
        index: id,
      };
      const global = new CircularProgressBar(`global-${id}`, globalConfig);
      global.initial();
      isInit.current = true;
    }
  }, [fontSize, id, percent, size]);

  return (
    <div
      className={`global-${id}`}
      data-pie={`{ "percent": ${percent}, "lineargradient": ${colorByPercentage(percent)}}`}
    />
  );
};

export default CircularProgress;
