import React, { useEffect } from 'react';
import './style.scss';
import { useRecoilState } from 'recoil';
import { GenderType } from 'src/constants';
import Title from 'src/components/Title';
import ButtonNavigate from 'src/components/ButtonNavigate';
import DotStep from 'src/components/DotStep';
import { genderState } from './state';
import clsx from 'classnames';
import HappyMini from '@happymini/core';
import { logEvent } from 'src/plugins/firebase';

const Gender = () => {
  const [gender, setGender] = useRecoilState<GenderType>(genderState);

  useEffect(() => {
    HappyMini.Notification.requestPermission();
  }, []);

  return (
    <>
      <div className='onboarding-gender'>
        <img onClick={() => HappyMini.App.close()} src='/images/back.svg' alt='' />
        <DotStep keyActive={1} />
        <Title title='Lựa chọn giới tính của bạn' />
        <div className='onboarding-gender-type tw-flex tw-justify-between'>
          <div
            className={clsx('onboarding-gender-male', {
              'onboarding-gender--active': gender === GenderType.male,
            })}
            onClick={() => setGender(GenderType.male)}
          >
            <div className='onboarding-gender-male-block tw-text-center'>
              <img className='tw-mt-1' src='/images/onboarding/Group 503.svg' alt='' />
            </div>
            <p className='fs-18 tw-text-center color-primary'>Nam</p>
          </div>
          <div
            className={clsx('onboarding-gender-female', {
              'onboarding-gender--active': gender === GenderType.female,
            })}
            onClick={() => setGender(GenderType.female)}
          >
            <div className='onboarding-gender-female-block tw-text-center'>
              <img className='tw-mt-1' src='/images/onboarding/Group 503 (1).svg' alt='' />
            </div>
            <p className='fs-18 tw-text-center color-primary'>Nữ</p>
          </div>
        </div>
        <ButtonNavigate
          onContinue={() => {
            logEvent('watee_setup_sex_onboarding');
          }}
          namePath='onboarding-weight'
        />
      </div>
    </>
  );
};

export default Gender;
