import { Picker } from '@happymini/react-ui';
import { PickerValue } from '@happymini/react-ui/es/components/picker-view';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import employee from 'src/api/employee';
import { weightOptions } from 'src/constants/setting';
import { useStoreState } from 'src/store';
import { toast } from 'src/utils/utilities';

const ChooseWeight = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useStoreState();
  const loadingRef = useRef(false);

  const weight = useMemo(() => {
    const employeeWeight = store('employeeSetting')?.weight;
    if (!employeeWeight) {
      return '';
    }
    return employeeWeight.toString();
  }, [store]);

  useImperativeHandle(ref, () => {
    return {
      setVisible,
    };
  });

  const onHandleChange = useCallback(
    async (values: PickerValue[]) => {
      const [value] = values;
      if (value) {
        loadingRef.current = true;
        setLoading(true);
        const data = await employee.updateEmployeeSetting({
          weight: +value,
        });
        setLoading(false);
        loadingRef.current = false;
        if (!data.success) {
          return toast().error(data.message);
        }
        setVisible(false);
        toast().success(data.message);
        setStore('employeeSetting', data.data);
      }
    },
    [setStore]
  );

  return (
    <>
      <div className='tw-text-[#DA8BF3] fs-16' onClick={() => setVisible(true)}>
        {weight} Kg
      </div>
      <Picker
        popupStyle={{
          borderRadius: '24px 24px 0px 0px',
        }}
        confirmText={<span className='fs-18 tw-text-[#DA8BF3] font-weight-600'>Lưu</span>}
        cancelText={
          <span className='fs-18 font-weight-600' style={{ color: 'transparent' }}>
            Lưu
          </span>
        }
        columns={weightOptions}
        title={
          <>
            <p className='color-primary font-weight-700 fs-20 tw-mb-5'>Chọn cân nặng</p>
            <p className='color-primary-8'>Cân nặng sẽ ảnh hưởng đến lượng nước cần nạp mỗi ngày của bạn</p>
          </>
        }
        defaultValue={[weight]}
        visible={visible}
        onConfirm={onHandleChange}
        loading={loading}
        onClose={() => setVisible(loadingRef.current)}
      />
    </>
  );
});

export default ChooseWeight;
