import React from 'react';
import { BlockItemType } from 'src/typings/setting';
import {Nav} from "../router";

const BlockList = (props: BlockItemType) => {
  const { id, contentLeft, contentRight, title, navigate, onClickRow } = props;
  return (
    <div onClick={onClickRow} className='block-item tw-relative'>
      <div
        className='tw-flex tw-justify-between tw-items-center'
        onClick={() =>
          navigate?.length ? Nav.push(navigate, id ? {
              params: {
                  id
              }
          } : {}) : undefined
        }
      >
        <div className='tw-flex tw-items-center'>
          {contentLeft}
          <span className='fs-16 color-primary'>{title}</span>
        </div>

        {contentRight}
      </div>
    </div>
  );
};

export default BlockList;
