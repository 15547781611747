import React, { FC, useCallback, useState } from 'react';
import './style.scss';
import { Mask, Form, Input, Button } from '@happymini/react-ui';
import { DrinkInfo, WaterType } from 'src/typings/water';
import { roundToTwo, toast, today } from 'src/utils/utilities';
import waterTemplates from 'src/api/water-templates';
import drinkWater from 'src/api/drink-water';
import { iconMap } from 'src/constants/water';
import HeartIcon from 'src/components/Icons/Heart';
import { logEvent } from 'src/plugins/firebase';
import { queryClient } from 'src/utils/react-query';
import { fetchDrinkInfo } from 'src/services/drink';

const ModalChooseDrink: FC<{
  water: WaterType;
  onClose: CallableFunction;
  onSaveTemplate: CallableFunction;
  onDrinkSuccess: (data: DrinkInfo) => void;
}> = ({ water, onClose, onSaveTemplate, onDrinkSuccess }) => {
  const [ml, setMl] = useState(500);
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSelectFavorite, setIsSelectFavorite] = useState(false);

  const handleOnClose = useCallback(() => {
    if (!isSubmitting) {
      onClose();
    }
  }, [isSubmitting, onClose]);

  const saveAsTemplate = useCallback(() => {
    return new Promise((resolve) => {
      form.validateFields().then(async (value) => {
        const { ml } = value;
        const data = await waterTemplates.createWaterTemplate({
          ml: +ml,
          waterType: water.id,
        });
        if (!data.success) {
          resolve(data);
          return toast().error(data.message);
        }
        onSaveTemplate();
        resolve(data);
      });
    });
  }, [form, onSaveTemplate, water.id]);

  const onSubmit = useCallback(() => {
    form.validateFields().then(async (value) => {
      const { ml } = value;
      if (!/[0-9]+/.test(ml)) {
        return toast().error('Lượng nước không hợp lệ');
      }
      setIsSubmitting(true);
      const data = await drinkWater.drinkWater({
        ml,
        waterType: water.id,
      });
      setIsSubmitting(false);
      if (!data.success) {
        return toast().error(data.message);
      }
      if (isSelectFavorite) {
        await saveAsTemplate();
      }
      onDrinkSuccess({
        ...data.data,
        isFavorite: isSelectFavorite,
      });
      logEvent('watee_drink_an_item', {
        item_name: water.name,
      });
      queryClient.fetchQuery(
        fetchDrinkInfo({
          date: today(),
        })
      );
      handleOnClose();
    });
  }, [form, handleOnClose, isSelectFavorite, onDrinkSuccess, saveAsTemplate, water.id, water.name]);

  return (
    <div className='chose-drink-modal-container'>
      <Mask />
      <div className='choose-drink-modal'>
        <div className='circle-drink-block'>
          <div className='circle-drink'>
            <img height='55' src={iconMap[water.key]} alt='' />
          </div>
        </div>
        <div className='choose-drink-modal-content'>
          <div className='tw-mb-5 fs-20 color-primary tw-text-center'>{water.name}</div>
          <Form initialValues={{ ml }} form={form} layout='horizontal'>
            <Form.Item
              label=''
              extra={
                <div className='extra-input'>
                  <a className='fs-20 color-primary'>ml</a>
                </div>
              }
              name='ml'
            >
              <Input
                className='tw-w-full'
                disabled={isSubmitting}
                type='number'
                onChange={(val) => {
                  if (val.length > 6 && !val.includes('.')) {
                    const value = val.substring(0, val.length - 1);
                    form.setFieldsValue({ ml: value });
                    return;
                  }

                  if (val.includes('.')) {
                    // eslint-disable-next-line prefer-const
                    let [first, last] = val.split('.');
                    if (last.length > 2) {
                      last = last.slice(0, 2);
                    }
                    return setMl(parseFloat(first + '.' + last));
                  }
                  setMl(+val);
                }}
                placeholder='Nhập khối lượng'
              />
            </Form.Item>
          </Form>
          <div className='color-pink tw-text-center tw-mt-4 tw-text-[#DA8BF3]'>
            ~ {roundToTwo(ml * water.rate)} ml nước (Tỷ lệ {water.rate})
          </div>
          <div onClick={() => setIsSelectFavorite((prev) => !prev)} className='tw-flex tw-justify-center tw-mt-[16px]'>
            <HeartIcon active={isSelectFavorite} />
            <span className='tw-text-[16px] tw-relative -tw-top-[2px] tw-ml-[10px]'>Lưu đồ uống yêu thích</span>
          </div>
          <div className='drink-button tw-mt-[20px]'>
            <Button loading={isSubmitting} onClick={onSubmit} className='drink-button-continue'>
              Chọn
            </Button>
          </div>
          {!isSubmitting && (
            <div onClick={() => handleOnClose()} className='modal-close'>
              <img src='/images/drink/drink-close-icon.svg' alt='' />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalChooseDrink;
