import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import './style.scss';
import { PullToRefresh, SpinLoading } from '@happymini/react-ui';
import BackButton from 'src/components/BackButton';
import Subtitle from 'src/components/pages/History/SubTitle';
import SafeAreaLayout from 'src/layouts/SafeAreaLayout';
import { DrinkInfo } from 'src/typings/water';
import { useParams } from 'react-router';
import drinkWater from 'src/api/drink-water';
import { toast, toHm } from 'src/utils/utilities';
import { statusRecord } from 'src/constants/pullToRefresh';
import { RouteNames } from 'src/constants/routeNames';
import CircularProgress from 'src/components/CircularProgress';
import { iconMap } from 'src/constants/water';
import EmptyData from '../../components/pages/History/EmptyData';

const HistoryDetail = () => {
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState<DrinkInfo>({ current: 0, histories: [], percentage: 0, total: 0 });
  const [height, setHeight] = useState('100vh');
  const { date } = useParams<{ date: string }>();
  const heightRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (heightRef.current) {
      const top = heightRef.current.getBoundingClientRect().y;
      const screenHeight = window.innerHeight;
      setHeight(`${screenHeight - top}px`);
    }
  }, []);

  const fetchHistoryDetail = useCallback(async () => {
    const data = await drinkWater.getHistoryByDate(date);
    if (!data.success) {
      return toast().error(data.message);
    }
    setDetail(data.data);
  }, [date]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchHistoryDetail();
      setLoading(false);
    })();
  }, [fetchHistoryDetail]);

  const dayMap = {
    0: 'CN',
    1: 'T2',
    2: 'T3',
    3: 'T4',
    4: 'T5',
    5: 'T6',
    6: 'T7',
  };

  const dateArr = date.split('-');
  if (dateArr[0].length === 1) {
    dateArr[0] = `0${dateArr[0]}`;
  }

  const dayInWeek = new Date(dateArr.reverse().join('-')).getDay() as keyof typeof dayMap;

  return (
    <section className='section section-bg onboarding-bg water-detail'>
      <SafeAreaLayout className='section-container'>
        <BackButton pathName={RouteNames.APP_HISTORY} title='Chi tiết lượng nước' />
        <div ref={heightRef} />
        {loading ? (
          <SpinLoading className='tw-m-auto tw-mt-[100px]' color='#fff' style={{ '--size': '50px' }} />
        ) : (
          <div style={{ height }} className='tw-overflow-auto'>
            <PullToRefresh
              onRefresh={fetchHistoryDetail}
              renderText={(status) => <div className='tw-text-white'>{statusRecord[status]}</div>}
            >
              <div className='water-detail-progress tw-flex tw-flex-col tw-content-between tw-items-center tw-mb-10'>
                <span className='color-primary tw-text-[16px] tw-pb-4'>
                  {dayMap[dayInWeek]}, {date}
                </span>
                <CircularProgress percent={detail?.percentage as number} />
                <span className='tw-text-[#DA8BF3] tw-text-[20px] tw-font-[600] tw-pt-4'>
                  {detail?.current} / {detail?.total} ml
                </span>
              </div>
              {detail?.histories.length ? (
                <>
                  <Subtitle subtitle='Chi tiết đồ uống' />
                  {detail?.histories.map((history) => (
                    <div
                      key={history.id}
                      className='tw-flex tw-items-start tw-justify-between tw-rounded-2xl tw-p-[22px] tw-mb-3'
                      style={{
                        border: '2px solid rgba(255, 255, 255, 0.15)',
                        backdropFilter: 'blur(2px)',
                        background: 'rgba(255, 255, 255, 0.05)',
                      }}
                    >
                      <div className='tw-flex tw-items-center'>
                        <img width={40} src={iconMap[history.key]} alt='' />
                        <div className='tw-flex tw-flex-col tw-justify-between tw-ml-4'>
                          <p className='color-primary fs-16'>{history.name}</p>
                          <p className='tw-font-[600] tw-text-[16px] tw-mt-2 tw-text-[#DA8BF3] '>
                            {history.ml} ml ~ {history.transferedMl} ml nước
                          </p>
                        </div>
                      </div>
                      <div className='tw-text-white fs-16'>{toHm(history.createdAt)}</div>
                    </div>
                  ))}
                </>
              ) : (
                <EmptyData className='tw-pt-[30px]' title='Bạn chưa có đồ uống gì hôm nay' showImage />
              )}
            </PullToRefresh>
          </div>
        )}
      </SafeAreaLayout>
    </section>
  );
};

export default HistoryDetail;
