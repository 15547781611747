import { StepType, TourProvider } from '@reactour/tour';
import { FC, PropsWithChildren } from 'react';

export const steps: StepType[] = [
  {
    selector: '.tour-choose-water',
    content: 'Chọn thêm các đồ uống trong ngày',
  },
  {
    selector: '.tour-history',
    content: 'Lịch sử uống nước',
  },
  {
    selector: '.tour-setting',
    content: 'Chỉnh sửa thông tin và cài đặt cá nhân',
  },
  {
    selector: '.tour-droplet',
    content: '<p>Phần trăm lượng nước uống hàng ngày của bạn.</p> <p>“Click” để xem chi tiết thêm.</p>',
  },
  {
    selector: '.tour-droplet-info',
    content: 'Thống kế lượng nước uống trong ngày',
  },
];

const Content: FC<{ content: string }> = ({ content }) => {
  return <div className='tw-whitespace-pre' dangerouslySetInnerHTML={{ __html: content }}></div>;
};

const Close: FC<{ onClick: React.MouseEventHandler<HTMLButtonElement> }> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        position: 'absolute',
        right: -10,
        top: -10,
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 24,
      }}
    >
      <img src='/images/close-icon.png' />
    </button>
  );
};

const Tour: FC<PropsWithChildren> = ({ children }) => {
  const preventClick = (e: MouseEvent) => {
    const mask = document.querySelector('.reactour__mask');
    const popover = document.querySelector('.reactour__popover');
    const skipTour = document.querySelector('.skip-tour');
    const startButton = document.querySelector('.tour-end');
    const isClickTour = Array.from([mask, popover, skipTour, startButton]).some((element) => {
      return e.target == element || element?.contains(e.target as Node);
    });
    if (!isClickTour) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const onAfterOpen = () => {
    window.addEventListener('click', preventClick, true);
  };

  const onBeforeClose = () => {
    window.removeEventListener('click', preventClick, true);
  };

  const onClickClose = ({ setCurrentStep, currentStep, steps, setIsOpen }: any) => {
    if (steps) {
      if (currentStep === steps.length - 1) {
        setIsOpen(false);
      }
      setCurrentStep((s: any) => (s === steps.length - 1 ? 0 : s + 1));
    }
  };

  return (
    <TourProvider
      afterOpen={onAfterOpen}
      components={
        {
          Close,
          Content,
        } as any
      }
      steps={steps}
      styles={{
        popover: (base) => ({
          ...base,
          borderRadius: 10,
        }),
        maskWrapper: (base) => ({
          ...base,
          background: 'red',
        }),
      }}
      onClickMask={onClickClose}
      onClickClose={onClickClose}
      showBadge={false}
      showNavigation={false}
      beforeClose={onBeforeClose}
    >
      {children}
    </TourProvider>
  );
};

export default Tour;
