import { isString, omitBy, transform, isArray, snakeCase, isObject, camelCase } from 'lodash';
import { toast as toastify, ToastOptions, ToastContent, Slide } from 'react-toastify';

export const toast = () => {
  const defaultOptions: ToastOptions = {
    position: 'bottom-center',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
    style: {
      bottom: 30,
      width: '95%',
      borderRadius: 6,
      margin: '0 auto',
      padding: '12px',
    },
    transition: Slide,
  };
  return {
    success: <T = any>(message: ToastContent<T>, options?: ToastOptions) => {
      toastify.success(message, {
        ...defaultOptions,
        ...options,
      });
    },
    info: <T = any>(message: ToastContent<T>, options?: ToastOptions) => {
      toastify.info(message, {
        ...defaultOptions,
        ...options,
      });
    },
    warning: <T = any>(message: ToastContent<T>, options?: ToastOptions) => {
      toastify.warning(message, {
        ...defaultOptions,
        ...options,
      });
    },
    error: <T = any>(message: ToastContent<T>, options?: ToastOptions) => {
      toastify.error(message, {
        ...defaultOptions,
        ...options,
      });
    },
  };
};

export const omitByMultiple = (data: any, callbacks: CallableFunction[]) => {
  callbacks.forEach((callback) => {
    data = omitBy(data, callback);
  });
  return data;
};

export const snakeCasify = (obj: any) => {
  if (isString(obj)) {
    return obj;
  }
  return transform(obj, (acc: any, value, key: string, target) => {
    const camelKey = isArray(target) ? key : snakeCase(key);
    acc[camelKey] = isObject(value) ? snakeCasify(value) : value;
  });
};

export const camelCasify = (obj: any) => {
  if (isString(obj)) {
    return obj;
  }
  return transform(obj, (acc: any, value, key: string, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);
    acc[camelKey] = isObject(value) ? camelCasify(value) : value;
  });
};

export const roundToTwo = (num: number) => {
  return +(Math.round(+(num + 'e+2')) + 'e-2');
};

export const today = (splitChar = '-') => {
  const date = new Date();
  return [date.getDate(), date.getMonth() + 1, date.getFullYear()].join(splitChar);
};

export const uuid = () => {
  const tempUrl = URL.createObjectURL(new Blob());
  const uuid = tempUrl.toString();
  URL.revokeObjectURL(tempUrl);
  return uuid.substr(uuid.lastIndexOf('/') + 1);
};

export const toHm = (dateStr: string) => {
  const date = new Date(dateStr);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const parseHours = hours < 10 ? `0${hours}` : hours;
  const parseMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${parseHours}:${parseMinutes}`;
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
