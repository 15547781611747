import React, { FC, PropsWithChildren } from 'react';
import './style.scss';
import { Mask } from '@happymini/react-ui';

const PureDrinkModal: FC<PropsWithChildren<{ onClose: CallableFunction; canClose?: boolean }>> = ({
  children,
  onClose,
  canClose = true,
}) => {
  return (
    <div className='chose-drink-modal-container'>
      <Mask onMaskClick={() => canClose && onClose()} />
      <div className='drink-success-modal'>
        <img className='icon' src='/images/icons/three-droplets.svg' />
        <div className='tw-text-center choose-drink-modal-content'>{children}</div>
        {canClose && (
          <div onClick={() => onClose()} className='modal-close'>
            <img src='/images/drink/drink-close-icon.svg' alt='' />
          </div>
        )}
      </div>
    </div>
  );
};

export default PureDrinkModal;
