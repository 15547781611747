import React from 'react';
import './style.scss';
import Title from 'src/components/Title';
import ButtonNavigate from 'src/components/ButtonNavigate';
import DotStep from 'src/components/DotStep';
import { PickerView } from '@happymini/react-ui';
import { useSetRecoilState } from 'recoil';
import { weightState } from './state';
import { RouteNames } from 'src/constants/routeNames';
import { Nav } from 'src/router';
import { weightOptions } from 'src/constants/setting';
import { logEvent } from 'src/plugins/firebase';

const Weight = () => {
  const setWeight = useSetRecoilState(weightState);

  return (
    <>
      <div className='onboarding-weight'>
        <img onClick={() => Nav.pop(RouteNames.ONBOARDING_GENDER)} src='/images/back.svg' alt='' />
        <DotStep keyActive={2} />
        <Title title='Cân nặng của bạn' />
        <div className='mt-60'>
          <PickerView
            onChange={([val]) => {
              // @ts-ignore
              setWeight(val as number);
            }}
            defaultValue={['50']}
            mouseWheel={false}
            columns={weightOptions}
          />
        </div>
        <ButtonNavigate
          onContinue={() => {
            logEvent('watee_setup_weight_onboarding');
          }}
          namePath='onboarding-setting'
        />
      </div>
    </>
  );
};

export default Weight;
