import { InfiniteScroll, PullToRefresh } from '@happymini/react-ui';
import { groupBy } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import drinkWater from 'src/api/drink-water';
import BackButton from 'src/components/BackButton';
import HistoryItem from 'src/components/pages/History/Item';
import Subtitle from 'src/components/pages/History/SubTitle';
import { statusRecord } from 'src/constants/pullToRefresh';
import { RouteNames } from 'src/constants/routeNames';
import SafeAreaLayout from 'src/layouts/SafeAreaLayout';
import { DrinkWaterHistory } from 'src/typings/water';
import { toast } from 'src/utils/utilities';
import './style.scss';
import EmptyData from '../../components/pages/History/EmptyData';

const History = () => {
  const [todayHistories, setTodayHistories] = useState<DrinkWaterHistory[]>([]);
  const [histories, setHistories] = useState<DrinkWaterHistory[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [height, setHeight] = useState('100vh');
  const heightRef = useRef<HTMLDivElement | null>(null);
  const nextPageNum = useRef<number>(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (heightRef.current) {
      const top = heightRef.current.getBoundingClientRect().y;
      const screenHeight = window.innerHeight;
      setHeight(`${screenHeight - top}px`);
    }
  }, []);

  const fetchHistories = useCallback(async (forceRender = false) => {
    setIsLoading(true);
    const data = await drinkWater.getHistories({
      page: forceRender ? 1 : nextPageNum.current,
    });
    setIsLoading(false);
    if (!data.success) {
      setHasMore(false);
      return toast().error(data.message);
    }
    if (data.histories.length) {
      const { true: today, false: past } = groupBy<DrinkWaterHistory>(data.histories, 'isToday');
      if (today) {
        setTodayHistories(today);
      }
      if (past) {
        setHistories((prev) => (forceRender ? past : [...prev, ...past]));
      }
    }
    setHasMore(data.hasMorePage);
    nextPageNum.current = data.nextPage ?? 1;
  }, []);

  const loadMore = useCallback(async () => {
    await fetchHistories();
  }, [fetchHistories]);

  return (
    <>
      <section className='section section-bg onboarding-bg history'>
        <SafeAreaLayout className='section-container'>
          <BackButton pathName={RouteNames.APP_HOME} title='Lịch sử' />
          <div ref={heightRef} />
          <div style={{ height }} className='tw-overflow-auto'>
            <PullToRefresh
              onRefresh={() => fetchHistories(true)}
              renderText={(status) => <div className='tw-text-white'>{statusRecord[status]}</div>}
            >
              {!todayHistories.length && !histories.length ? (
                !isLoading && <EmptyData showImage />
              ) : (
                <>
                  <Subtitle subtitle='Hôm nay' />
                  <div className='tw-mb-7'>
                    {todayHistories.length
                      ? todayHistories.map((history) => <HistoryItem key={history.date} history={history} />)
                      : !isLoading && <EmptyData className='tw-mt-[20px]' title='Bạn chưa có đồ uống gì hôm nay' />}
                  </div>
                  <Subtitle subtitle='Lịch sử' />
                  <div className=''>
                    {histories.length
                      ? histories.map((history) => <HistoryItem key={history.date} history={history} />)
                      : !isLoading && <EmptyData />}
                  </div>
                </>
              )}
            </PullToRefresh>

            {hasMore && <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />}
          </div>
        </SafeAreaLayout>
      </section>
    </>
  );
};

export default History;
