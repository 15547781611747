import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';
import httpCodes from 'src/constants/httpCode';
import { camelCasify, snakeCasify, toast } from 'src/utils/utilities';
import HappyMini from '@happymini/core';
import { AxiosInstance } from 'src/typings/axios';

const authURL = process.env.REACT_APP_AUTH_URL as string;
HappyMini.Axios.setBaseURL(authURL);

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000,
  headers: {
    platform: 2,
  },
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  if (!config.headers) config.headers = {};
  if (isEmpty(config.headers.authorization)) {
    const tokenType = HappyMini.Auth.tokenType;
    const accessToken = HappyMini.Auth.accessToken;
    config.headers.authorization = tokenType + ' ' + accessToken;
  }
  if (!isEmpty(config.data)) {
    config.data = snakeCasify(config.data);
  }
  if (!isEmpty(config.params)) {
    config.params = snakeCasify(config.params);
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    response.data = camelCasify(response.data);
    return response.data;
  },
  async (error) => {
    if (!isEmpty(error.response?.data)) {
      error.response.data = camelCasify(error.response.data);
    } else {
      error.response = camelCasify(error.response);
    }
    if (error.message == 'Network Error') {
      return { success: false, message: 'Lỗi kết nối đến server' };
    }
    if (error.code == 'ECONNABORTED') {
      return { success: false, message: 'Server không phản hồi' };
    }
    const { ACCESS_TOKEN_EXPIRED, UNAUTHORIZED } = httpCodes;
    if (error.response.status == ACCESS_TOKEN_EXPIRED) {
      return await HappyMini.Axios.onTokenExpired(axiosInstance, error, HappyMini.Auth.syncAuth, (err) => {
        HappyMini.App.close();
      });
    }
    if (error.response.status == UNAUTHORIZED) {
      toast().error('Phiên đăng nhập đã hết hạn');
      return HappyMini.App.close();
    }
    return (
      error.response?.data || {
        success: false,
        message: 'Đã có lỗi xảy ra, xin vui lòng thử lại',
      }
    );
  }
);

export default axiosInstance;
