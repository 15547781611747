import axiosInstance from 'src/plugins/axios';
import { PromiseResponse } from 'src/typings/axios';
import { DrinkInfo, DrinkWater } from 'src/typings/water';

export default {
  getHistoryByDate: (date: string): PromiseResponse<{ data: DrinkInfo }> =>
    axiosInstance.get(`/water-reminder/drinking-water/history/${date}`),
  getListWaters: () => axiosInstance.get('/water-reminder/drinking-water/list-water'),
  drinkWater: (params: DrinkWater) => axiosInstance.post('/water-reminder/drinking-water', params),
  drinkWaterByTemplate: (templateId: number) =>
    axiosInstance.post(`/water-reminder/drinking-water/template/${templateId}`),
  getHistories: (params: any) =>
    axiosInstance.get('/water-reminder/drinking-water/histories', {
      params,
    }),
};
