import React, { FC } from 'react';
import { DrinkWaterHistory } from 'src/typings/water';
import { Nav } from 'src/router';
import { RouteNames } from 'src/constants/routeNames';
import CircularProgress from 'src/components/CircularProgress';

type Props = {
  history: DrinkWaterHistory;
};

const HistoryItem: FC<Props> = ({ history }) => {
  return (
    <div
      onClick={() =>
        Nav.push(RouteNames.APP_HISTORY_DETAIL, {
          params: {
            date: history.date,
          },
        })
      }
      className='tw-flex tw-items-center history-item tw-mb-3'
    >
      <div className='history-progress tw-mr-4'>
        <CircularProgress fontSize='20px' percent={history.statistics.percentage} size={70} />
      </div>
      <div className='history-info'>
        <p className='tw-text-[white] tw-font-[400] tw-mb-0.5 tw-text-[16px] tw-opacity-[0.5]'>{history.date}</p>
        <p className='tw-text-white tw-mb-1.5 tw-text-[16px] tw-whitespace-pre-wrap tw-break-words tw-pr-[5px]'>
          {history.evaluateTitle}
        </p>
        <p className='tw-font-[600] tw-text-[#DA8BF3] tw-text-[16px] tw-mb-[5px]'>
          {history.statistics.current} / {history.statistics.total} ml
        </p>
      </div>
    </div>
  );
};

export default HistoryItem;
