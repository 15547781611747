import { RouterOutlet } from '@topcv/react-router-map';
import clsx from 'classnames';
import { PropsType } from 'src/typings/router';
import SafeAreaLayout from './SafeAreaLayout';

const OnboardingLayout = (props: PropsType) => {
  const onBoardingBackground = ['/onboarding', '/onboarding/'];
  const { pathname } = props.location;

  return (
    <>
      <section
        className={clsx('section section-bg onboarding-bg', onBoardingBackground.includes(pathname) && 'onboarding')}
      >
        <SafeAreaLayout className='section-container'>
          <RouterOutlet {...props} />
        </SafeAreaLayout>
      </section>
    </>
  );
};

export default OnboardingLayout;
