import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Route, Router } from 'react-router';
import { createBrowserHistory } from 'history';
import { RecoilRoot } from 'recoil';
import { AnimationRoutes, ConfigProvider } from '@happymini/react-ui';
import viVN from '@happymini/react-ui/es/locales/vi-VN';
import GuideTour from './GuideTour';
import HappyMini, { Lib } from '@happymini/core';
import HappyMiniDebugbar from '@happymini/debugbar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from './ErrorBoundary';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './utils/react-query';

export const browserHistory = createBrowserHistory();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const appEnvironment = process.env.REACT_APP_ENVIRONMENT;

const employee = HappyMini.Auth.employee;
if (appEnvironment == 'dev' || employee.id == '2549') {
  Lib.use(HappyMiniDebugbar);
}

root.render(
  <ErrorBoundary>
    <Router history={browserHistory}>
      <RecoilRoot>
        <ConfigProvider locale={viVN}>
          <QueryClientProvider client={queryClient}>
            <GuideTour>
              <AnimationRoutes>
                <Route component={App} />
              </AnimationRoutes>
            </GuideTour>
          </QueryClientProvider>
        </ConfigProvider>
        <ToastContainer />
      </RecoilRoot>
    </Router>
  </ErrorBoundary>
);

reportWebVitals();
