import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import './style.scss';
import { PullToRefresh, SpinLoading } from '@happymini/react-ui';
import BackButton from 'src/components/BackButton';
import SafeAreaLayout from 'src/layouts/SafeAreaLayout';
import { WaterType } from 'src/typings/water';
import drinkWater from 'src/api/drink-water';
import { toHm } from 'src/utils/utilities';
import { statusRecord } from 'src/constants/pullToRefresh';
import { RouteNames } from 'src/constants/routeNames';
import { iconMap } from 'src/constants/water';

const IntroduceWater = () => {
  const [loading, setLoading] = useState(false);
  const [listWaters, setListWaters] = useState<WaterType[]>([]);
  const [height, setHeight] = useState('100vh');
  const heightRef = useRef<HTMLDivElement | null>(null);

  const fetchListWaters = useCallback(async () => {
    const data = await drinkWater.getListWaters();
    if (data.success) {
      setListWaters(data.data);
    }
  }, []);

  useEffect(() => {
    if (heightRef.current) {
      const top = heightRef.current.getBoundingClientRect().y;
      const screenHeight = window.innerHeight;
      setHeight(`${screenHeight - top}px`);
    }
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchListWaters();
      setLoading(false);
    })();
  }, [fetchListWaters]);

  return (
    <section className='section section-bg onboarding-bg water-detail'>
      <SafeAreaLayout className='section-container'>
        <BackButton pathName={RouteNames.APP_HISTORY} title='Giới thiệu đồ uống' />
        <div ref={heightRef} />
        {loading ? (
          <SpinLoading className='tw-m-auto tw-mt-[100px]' color='#fff' style={{ '--size': '50px' }} />
        ) : (
          <div style={{ height }} className='tw-overflow-auto'>
            <p className='tw-text-center tw-text-[#fff] tw-text-[16px] tw-opacity-[0.8] tw-font-[400] tw-mb-[28px]'>
              Trên thực tế, mỗi loại đồ uống sẽ cung cấp lượng nước khác nhau cho cơ thể của bạn.
            </p>
            {listWaters.map((water) => (
              <div
                key={water.id}
                className='tw-flex tw-items-center tw-justify-between tw-rounded-2xl tw-p-[10px] tw-mb-3'
                style={{
                  border: '2px solid rgba(255, 255, 255, 0.15)',
                  backdropFilter: 'blur(2px)',
                  background: 'rgba(255, 255, 255, 0.05)',
                }}
              >
                <div className='tw-flex tw-items-center'>
                  <img width={40} src={iconMap[water.key]} alt='' />
                  <div className='tw-flex tw-flex-col tw-justify-between tw-ml-4'>
                    <p className='color-primary fs-16'>{water.name}</p>
                  </div>
                </div>
                <div className='tw-text-white tw-text-[16px]'>{water.rate}</div>
              </div>
            ))}
          </div>
        )}
      </SafeAreaLayout>
    </section>
  );
};

export default IntroduceWater;
