import React, { useRef } from 'react';
import './style.scss';
import BackButton from 'src/components/BackButton';
import { BlockItemType } from 'src/typings/setting';
import BlockList from 'src/components/BlockList';
import ChooseGender from 'src/components/pages/Setting/ChooseGender';
import SafeAreaLayout from 'src/layouts/SafeAreaLayout';
import { RouteNames } from 'src/constants/routeNames';
import ChooseWeight from 'src/components/pages/Setting/ChooseWeight';
import NotificationSwitch from 'src/components/pages/Setting/NotificationSwitch';

const Setting = () => {
  const weightRef = useRef<{ setVisible: (visible: boolean) => void }>();

  const settingList: BlockItemType[] = [
    {
      id: 1,
      contentLeft: <img src='/images/icons/bell.svg' className='tw-pr-3.5' alt='' />,
      title: 'Cài đặt thông báo',
      contentRight: <NotificationSwitch />,
    },
    {
      id: 2,
      contentLeft: <img src='/images/icons/gender.svg' className='tw-pr-3.5' alt='' />,
      title: 'Giới tính',
      contentRight: <ChooseGender />,
    },
    {
      id: 3,
      contentLeft: <img src='/images/icons/chart.svg' className='tw-pr-3.5' alt='' />,
      title: 'Cân nặng',
      contentRight: <ChooseWeight ref={weightRef} />,
      onClickRow: () => weightRef.current && weightRef.current.setVisible(true),
    },
    {
      id: 4,
      contentLeft: <img src='/images/icons/coffee.svg' className='tw-pr-3.5' alt='' />,
      title: 'Giới thiệu đồ uống',
      contentRight: '',
      navigate: RouteNames.APP_DRINK_INTRO,
    },
    {
      id: 5,
      contentLeft: <img src='/images/icons/book.svg' className='tw-pr-3.5' alt='' />,
      title: 'Cẩm nang',
      contentRight: '',
      navigate: RouteNames.APP_HANDBOOK,
    },
  ];
  return (
    <section className='section onboarding-bg section-bg setting'>
      <SafeAreaLayout className='section-container'>
        <BackButton pathName={RouteNames.APP_HOME} title='Cài đặt' />
        <div className='setting-block'>
          {settingList.map((setting) => (
            <BlockList
              key={setting.id}
              contentLeft={setting.contentLeft}
              title={setting.title}
              contentRight={setting.contentRight}
              navigate={setting.navigate}
              onClickRow={setting.onClickRow}
            />
          ))}
        </div>
      </SafeAreaLayout>
    </section>
  );
};

export default Setting;
