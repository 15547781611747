export const colorByPercentage = (percentage: number) => {
  const colors = {
    80: '["#9DFFCA", "#5ED478"]',
    50: '["#45A6FF", "#0038FF"]',
    20: '["#FFDE9D", "#FFA24B"]',
    0: '["#FF4D55", "#FF4D55"]',
  };
  let progressColor = '';
  Object.entries(colors).forEach(([stage, color]) => {
    if (percentage >= +stage) {
      return (progressColor = color);
    }
  });
  return progressColor;
};
