import React from 'react';

interface ITitleProps {
  title?: string;
  description?: string;
}

const Title = (props: ITitleProps) => {
  const { title, description = 'Thông tin về giới tính, cân nặng sẽ giúp chúng tôi đề xuất lượng nước phù hợp' } =
    props;

  return (
    <div className='onboarding-gender-title tw-text-center'>
      <p className='fs-22 font-weight-700 tw-mb-3 color-primary'>{title}</p>
      <p className='color-primary-8 fs-16'>
        {description}
      </p>
    </div>
  );
};

export default Title;
