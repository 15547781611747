import React from 'react';

const ActiveDot = () => {
  return (
    <div style={{ margin: '0 9px', position: 'relative' }}>
      <div className='onboarding-step-active'></div>
      <div className='onboarding-step-active--fill'></div>
    </div>
  );
};

const InActiveDot = () => <div className='onboarding-step-item'></div>;

const DotStep = (props: { keyActive: number }) => {
  const { keyActive } = props;

  return (
    <div className='onboarding-step tw-mb-9'>
      {keyActive === 1 ? <ActiveDot /> : <InActiveDot />}
      {keyActive === 2 ? <ActiveDot /> : <InActiveDot />}
      {keyActive === 3 ? <ActiveDot /> : <InActiveDot />}
    </div>
  );
};

export default DotStep;
