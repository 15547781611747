import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent as analyticsLogEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? '#',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? '#',
  appId: process.env.REACT_APP_FIREBASE_APP_ID ?? '#',
  measurementId: process.env.REACT_APP_MEASUREMENT_ID ?? '#',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const logEvent = (eventName: string, params?: { [k: string]: string }) => {
  analyticsLogEvent(analytics, eventName, params);
};
