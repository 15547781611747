import React from 'react';
import './style.scss';
import { Button } from '@happymini/react-ui';
import HappyMini from '@happymini/core';
import { RouteNames } from 'src/constants/routeNames';
import { Nav } from 'src/router';

const Starter = () => {
  return (
    <>
      <img
        onClick={() => HappyMini.App.close()}
        className='onboarding-btn-close'
        src='/images/onboarding/close.svg'
        alt=''
        width={30}
      />
      <div className='onboarding-title tw-text-center'>
        <img width={150} src='/images/logo.png' />
      </div>
      <div className='onboarding-logo tw-text-center'>
        <span className='color-primary fs-20 onboarding-logo-percentage'>100%</span>
        <img src='/images/onboarding/droplets.png' alt='' />
      </div>
      <div className='hp-button'>
        <Button onClick={() => Nav.push(RouteNames.ONBOARDING_GENDER)}>Bắt đầu </Button>
      </div>
    </>
  );
};

export default Starter;
