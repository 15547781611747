import React, { useEffect, useMemo, useRef, useState } from 'react';
import Title from 'src/components/Title';
import './style.scss';
import SafeAreaLayout from 'src/layouts/SafeAreaLayout';
import HappyMini from '@happymini/core';
import { Button, Loading, PullToRefresh } from '@happymini/react-ui';
import { RouteNames } from 'src/constants/routeNames';
import { statusRecord } from 'src/constants/pullToRefresh';
import { useTour } from '@reactour/tour';
import { Nav } from 'src/router';
import { today } from 'src/utils/utilities';
import { steps } from 'src/GuideTour';
import { fetchDrinkInfo, useFetchDrinkInfo } from 'src/services/drink';
import { queryClient } from 'src/utils/react-query';

const employee = HappyMini.Auth.employee;
const screenWidth = window.innerWidth;
const screenHeight = window.innerHeight;

const ratio = 1 - screenWidth / screenHeight + 0.3;

const Home = () => {
  const { isOpen, setIsOpen, setCurrentStep, currentStep } = useTour();
  const dropletOutsideRef = useRef<HTMLDivElement | null>(null);
  const dropletInfoRef = useRef<HTMLDivElement | null>(null);
  const [dropletTopPosition, setDropletTopPosition] = useState(0);
  const { data: drinkInfo, isLoading } = useFetchDrinkInfo({ date: today() });

  const wavePositionPx = useMemo(() => {
    const percentage = drinkInfo?.data?.percentage ?? 0;
    const pixelByPercentage = [
      {
        percent: 0,
        pixel: 120,
      },
      {
        percent: 50,
        pixel: -15,
      },
      {
        percent: 100,
        pixel: -200,
      },
    ];
    const matchPixel = pixelByPercentage.find((_) => _.percent == percentage);
    if (matchPixel) {
      return matchPixel.pixel;
    }
    const maxIndex = pixelByPercentage.findIndex((_) => percentage <= _.percent);
    const minIndex = pixelByPercentage[maxIndex - 1] ? maxIndex - 1 : maxIndex;
    const max = pixelByPercentage[maxIndex];
    const min = pixelByPercentage[minIndex];
    const step = (max.pixel - min.pixel) / (max.percent - min.percent);
    return min.pixel + step * (percentage - min.percent);
  }, [drinkInfo?.data?.percentage]);

  const goTodayDetail = () => {
    Nav.push(RouteNames.APP_HISTORY_DETAIL, {
      params: {
        date: today(),
      },
    });
  };

  useEffect(() => {
    if (dropletOutsideRef.current && dropletInfoRef.current) {
      const dropletInfoRect = dropletInfoRef.current.getBoundingClientRect();
      const dropletOutsideRect = dropletOutsideRef.current.getBoundingClientRect();
      const top = dropletOutsideRect.top - (dropletInfoRect.top + dropletInfoRect.height);
      setDropletTopPosition(top - 20);
    }
    (async () => {
      const isFirstVisit = await HappyMini.Storage.getItem('isFirstVisit');
      console.log(isFirstVisit);
      if (isFirstVisit.success && !isFirstVisit.data) {
        HappyMini.Storage.setItem('isFirstVisit', 'visited');
        setTimeout(() => {
          setIsOpen(true);
        }, 300);
      }
    })();
  }, [setIsOpen]);

  return (
    <>
      <section className='section section-bg onboarding-bg'>
        <SafeAreaLayout className='section-container'>
          <PullToRefresh
            renderText={(status) => <div className='tw-text-white'>{statusRecord[status]}</div>}
            onRefresh={() =>
              queryClient.fetchQuery(
                fetchDrinkInfo({
                  date: today(),
                })
              )
            }
          >
            <div style={{ height: screenHeight - 100 }} className='tw-overflow-x-hidden tw-overflow-y-scroll'>
              {isOpen ? (
                <div
                  onClick={() => {
                    setCurrentStep(0);
                    setIsOpen(false);
                  }}
                  className='skip-tour'
                >
                  <Button className='skip-tour-btn'>Bỏ qua</Button>
                </div>
              ) : (
                <img
                  width={30}
                  onClick={() => HappyMini.App.close()}
                  className='tw-absolute'
                  src='/images/onboarding/close.svg'
                  alt=''
                />
              )}
              <div className='tw-mt-[25px]' />
              <Title title={`Hi, ${employee.fullName}`} description={`Hôm nay, ${today('/')}`} />
              <div
                onClick={goTodayDetail}
                className='tw-mt-[28px] tw-m-auto onboarding-info--value tw-flex tw-items-center tw-justify-between tour-droplet-info'
                ref={dropletInfoRef}
              >
                <img src='/images/onboarding/droplets-icon.svg' alt='' className='tw-mr-1.5' />
                <div>
                  <p className='font-weight-400 color-primary tw-mb-[5px] tw-text-[14px]'>Lượng nước đã uống</p>
                  <p className='font-weight-600 color-primary fs-16'>
                    {isLoading ? (
                      '-/- ML'
                    ) : (
                      <span>
                        {drinkInfo?.data?.current}/{drinkInfo?.data?.total} ML
                      </span>
                    )}
                  </p>
                </div>
              </div>
              <div
                onClick={goTodayDetail}
                style={{ top: dropletTopPosition }}
                className='tw-relative onboarding-droplets'
              >
                <div style={{ '--ratio': ratio } as React.CSSProperties} className='drop-shape drop tour-droplet'>
                  <div className='drop-percentage'>
                    {isLoading ? <Loading color='#fff' /> : <span>{drinkInfo?.data?.percentage}%</span>}
                  </div>
                  <div style={{ '--top-position': wavePositionPx + 'px' } as React.CSSProperties} className='wave' />
                </div>
                <div style={{ '--ratio': ratio + 0.2 } as React.CSSProperties} className='drop-shape drop-outside' />
                <div
                  ref={dropletOutsideRef}
                  style={{ '--ratio': ratio + 0.4 } as React.CSSProperties}
                  className='drop-shape drop-outside-2'
                />
              </div>

              {isOpen && currentStep === steps.length - 1 && (
                <div className='tour-end'>
                  <Button
                    onClick={() => {
                      setCurrentStep(0);
                      setIsOpen(false);
                    }}
                  >
                    Bắt đầu ngay
                  </Button>
                </div>
              )}
            </div>
          </PullToRefresh>
          <div className='onboarding-info-wave'>
            <div className='onboarding-info-menu tw-flex tw-justify-evenly tw-items-center tw-relative tw-top-[5px]'>
              <div onClick={() => Nav.push(RouteNames.APP_HISTORY)} className='tour-history'>
                <img width={44} src='/images/onboarding/history.png' alt='' />
              </div>
              <div onClick={() => Nav.push(RouteNames.APP_DRINK)} className='tw-relative'>
                <img width={120} src='/images/onboarding/pick-water.png' className='tour-choose-water' alt='' />
                <div
                  style={{ transform: 'translate(-50%, -50%)', left: '50%', width: '100%' }}
                  className='color-primary tw-absolute tw-bottom-[10px] tw-text-center'
                >
                  <span className='tw-text-[14px]'>Chọn đồ uống</span>
                </div>
              </div>
              <div onClick={() => Nav.push(RouteNames.APP_SETTING)} className='tour-setting'>
                <img width={44} src='/images/onboarding/user.png' alt='' />
              </div>
            </div>
          </div>
        </SafeAreaLayout>
      </section>
    </>
  );
};

export default Home;
