import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './style.scss';
import { JumboTabs, Swiper, SpinLoading, PullToRefresh, Button } from '@happymini/react-ui';
import SafeAreaLayout from 'src/layouts/SafeAreaLayout';
import { DrinkInfo, WaterTemplate, WaterType } from 'src/typings/water';
import { chunk } from 'lodash';
import { statusRecord } from 'src/constants/pullToRefresh';
import { RouteNames } from 'src/constants/routeNames';
import { Nav } from 'src/router';
import ModalChooseDrink from 'src/components/pages/Drink/ModalChooseDrink';
import waterTemplates from 'src/api/water-templates';
import drinkWater from 'src/api/drink-water';
import { toast, today } from 'src/utils/utilities';
import { iconMap } from 'src/constants/water';
import DrinkSuccessModal from 'src/components/pages/Drink/DrinkSuccessModal';
import RemoveTemplateModal from '../../components/pages/Drink/RemoveTemplateModal';
import { logEvent } from 'src/plugins/firebase';
import { queryClient } from 'src/utils/react-query';
import { fetchDrinkInfo } from 'src/services/drink';

type WaterTemplateWithAdditonal = WaterTemplate & { isRemoving: boolean };

const Drink = () => {
  const [listWaters, setListWaters] = useState<WaterType[]>([]);
  const [isFetchingList, setIsFetchingList] = useState(false);
  const [selectedWater, setSelectedWater] = useState<WaterType | null>();
  const [templates, setTemplates] = useState<WaterTemplateWithAdditonal[]>([]);
  const [isFetchingTemplates, setIsFetchingTemplates] = useState(false);
  const [successModal, setSuccessModal] = useState<{ visible: boolean; info: Partial<DrinkInfo>; type: string }>({
    visible: false,
    type: '',
    info: {},
  });
  const [removeTemplateModal, setRemoveTemplateModal] = useState<{ visible: boolean; info: Partial<WaterTemplate> }>({
    visible: false,
    info: {},
  });
  const [scrollHeight, setScrollHeight] = useState('100vh');
  const heightRef = useRef<HTMLDivElement | null>(null);
  const currentTemplate = useRef<WaterTemplateWithAdditonal>();
  const [isDrinkingTemplate, setIsDrinkingTemplate] = useState(false);

  useEffect(() => {
    if (heightRef.current) {
      const top = heightRef.current.getBoundingClientRect().y;
      const screenHeight = window.innerHeight;
      setScrollHeight(`${screenHeight - top - 20}px`);
    }
  }, []);

  const fetchListTemplates = useCallback(async () => {
    const data = await waterTemplates.listWaterTemplates();
    if (data.success) {
      setTemplates(data.data);
    }
  }, []);

  useEffect(() => {
    (async () => {
      setIsFetchingTemplates(true);
      await fetchListTemplates();
      setIsFetchingTemplates(false);
    })();
  }, [fetchListTemplates]);

  const fetchListWaters = useCallback(async () => {
    const data = await drinkWater.getListWaters();
    if (data.success) {
      setListWaters(data.data);
    }
  }, []);

  useEffect(() => {
    (async () => {
      setIsFetchingList(true);
      await fetchListWaters();
      setIsFetchingList(false);
    })();
  }, [fetchListWaters]);

  const renderListWaters = useMemo(() => {
    const itemPerPage = 9;
    const chunkItems = chunk(listWaters, itemPerPage);
    return chunkItems.map((waters, itemIndex) => {
      return (
        <Swiper.Item key={itemIndex}>
          <div className='list-drink-wrapper'>
            {waters.map((water) => (
              <div key={water.id} onClick={() => setSelectedWater(water)} className='list-drink-item'>
                <div className='list-drink-item-bubble'>
                  <div>
                    <img width={48} src={iconMap[water.key]} alt='' />
                  </div>
                </div>
                <div className='fs-14 tw-text-center'>{water.name}</div>
              </div>
            ))}
          </div>
        </Swiper.Item>
      );
    });
  }, [listWaters]);

  const onConfirmRemoveTemplate = useCallback(
    async (template: WaterTemplate) => {
      const templateIndex = templates.findIndex((t) => t.id == template.id);
      if (templateIndex !== -1) {
        const cloneTemplates = [...templates];
        const scopeTemplate = cloneTemplates[templateIndex];
        if (!scopeTemplate.isRemoving) {
          scopeTemplate.isRemoving = true;
          setTemplates(cloneTemplates);
          const data = await waterTemplates.deleteTemplate(template.id);
          if (!data.success) {
            scopeTemplate.isRemoving = false;
            setTemplates(cloneTemplates);
            return toast().error(data.message);
          }
          setRemoveTemplateModal({ visible: false, info: {} });
          await fetchListTemplates();
          toast().success(data.message);
        }
      }
    },
    [fetchListTemplates, templates]
  );

  const onDrinkByTemplate = useCallback(async () => {
    if (currentTemplate.current) {
      setIsDrinkingTemplate(true);
      const data = await drinkWater.drinkWaterByTemplate(currentTemplate.current.id);
      setIsDrinkingTemplate(false);
      if (!data.success) {
        return toast().error(data.message);
      }
      setSuccessModal({ visible: true, info: data.data, type: 'drink-success' });
      logEvent('watee_drink_an_item', {
        item_name: currentTemplate.current.name,
      });
      queryClient.fetchQuery(fetchDrinkInfo({ date: today() }));
      fetchListTemplates();
    }
  }, [fetchListTemplates]);

  return (
    <section className='section section-bg onboarding-bg choose-a-drink'>
      <SafeAreaLayout>
        <div className='tw-flex tw-justify-between tw-mb-6 tw-mr-5 tw-px-[20px]'>
          <div className='fs-20 font-weight-600'>Đồ uống yêu thích</div>
          <img
            className='tw-relative tw-right-[-20px]'
            src='/images/drink/drink-close-icon.svg'
            alt=''
            onClick={() => Nav.pop(RouteNames.APP_HOME)}
          />
        </div>
        <div className='choose-a-drink-today'>
          {isFetchingTemplates ? (
            <SpinLoading style={{ '--size': '40px' }} className='tw-mx-auto tw-mt-[50px]' color={'#fff'} />
          ) : (
            <JumboTabs activeKey={''}>
              {templates.map((template) => (
                <JumboTabs.Tab
                  className='drink-item-container'
                  key={template.id}
                  title=''
                  description={
                    <div
                      onClick={() => {
                        setSuccessModal({ visible: true, info: {}, type: 'template-confirm' });
                        currentTemplate.current = template;
                      }}
                      className='drink-item tw-mt-[10px]'
                    >
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setRemoveTemplateModal({ visible: true, info: template });
                        }}
                        className='tw-absolute tw-w-[24px] tw-h-[24px] tw-rounded-full tw-bg-[#C4CBEA] tw-flex tw-justify-center tw-items-center tw-top-[6px] tw-right-[5px]'
                      >
                        {template.isRemoving ? (
                          <SpinLoading color={'#102065'} style={{ '--size': '15px' }} />
                        ) : (
                          <img width={10} height={10} src='/images/close-icon.svg' alt='' />
                        )}
                      </div>
                      <img className='tw-mt-1' height='30' src={iconMap[template.key]} alt='' />
                      <div className='tw-ml-4 tw-flex tw-flex-col tw-items-start'>
                        <div className='color-primary fs-16'>{template.name}</div>
                        <div className='color-primary-5 fs-16'>{template.ml} ml</div>
                      </div>
                    </div>
                  }
                />
              ))}
            </JumboTabs>
          )}
        </div>
        <div className='list-drink'>
          <div className='list-drink-container'>
            <div className='tw-flex tw-items-center tw-justify-between'>
              <div className='color-primary tw-text-[16px] tw-mb-3 tw-mt-8 tw-ml-7 tw-font-[600] '>Thêm đồ uống:</div>
              <div
                onClick={() => Nav.push(RouteNames.APP_DRINK_INTRO)}
                className='tw-flex tw-items-center tw-mr-7 tw-mt-8'
              >
                <img src='/images/drink/information.svg' alt='' />
                <div className='drink-information tw-text-[16px]'>Giới thiệu</div>
              </div>
            </div>
            <div ref={heightRef} />
            {isFetchingList ? (
              <SpinLoading style={{ '--size': '40px' }} className='tw-mx-auto tw-mt-[100px]' color={'#fff'} />
            ) : (
              <PullToRefresh
                renderText={(status) => <div className='tw-text-white'>{statusRecord[status]}</div>}
                onRefresh={fetchListWaters}
              >
                <div style={{ height: scrollHeight }} className='tw-overflow-y-scroll'>
                  <Swiper>{renderListWaters}</Swiper>
                </div>
              </PullToRefresh>
            )}
          </div>
        </div>
        {selectedWater && (
          <ModalChooseDrink
            onDrinkSuccess={(info) => setSuccessModal({ visible: true, info, type: 'drink-success' })}
            onSaveTemplate={() => fetchListTemplates()}
            onClose={() => setSelectedWater(null)}
            water={selectedWater}
          />
        )}
        {successModal.visible && (
          <DrinkSuccessModal
            canClose={!isDrinkingTemplate}
            onClose={() => setSuccessModal({ visible: false, info: {}, type: '' })}
          >
            {successModal.type == 'drink-success' && (
              <>
                <p className='tw-text-[16px] tw-text-[#d5d5d5] tw-mt-[100px]'>
                  Bạn đã chọn đồ uống thành công. Hãy tiếp tục uống để đủ lượng nước cần thiết hôm nay nhé
                </p>
                <p className='tw-mt-[20px] tw-text-[16px]'> Lượng nước uống hôm nay</p>
                <p className='tw-text-[20px] tw-mt-[8px] tw-font-[600] tw-text-[#DA8BF3]'>
                  {successModal.info.current} / {successModal.info.total} ml
                </p>
              </>
            )}
            {successModal.type == 'template-confirm' && (
              <>
                <p className='tw-text-[16px] tw-text-[#d5d5d5] tw-mt-[100px]'>
                  Bạn có chắc chắn muốn thêm {currentTemplate.current?.ml}ml{' '}
                  {currentTemplate.current?.name.toLowerCase()} vào lượng nước uống hôm nay?
                </p>
                <div className='drink-button tw-mt-[30px]'>
                  <Button onClick={onDrinkByTemplate} loading={isDrinkingTemplate} className='drink-button-continue'>
                    Xác nhận
                  </Button>
                </div>
              </>
            )}
          </DrinkSuccessModal>
        )}

        {removeTemplateModal.visible && (
          <RemoveTemplateModal
            info={removeTemplateModal.info}
            onClose={() => setRemoveTemplateModal({ visible: false, info: {} })}
            onSubmit={onConfirmRemoveTemplate}
          />
        )}
      </SafeAreaLayout>
    </section>
  );
};

export default Drink;
