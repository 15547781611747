import React, { useEffect, useRef, useState } from 'react';
import BackButton from '../../components/BackButton';
import { HANDBOOK_LIST } from '../../constants/handbook';
import { useParams } from 'react-router';
import SafeAreaLayout from 'src/layouts/SafeAreaLayout';
import { logEvent } from 'src/plugins/firebase';

const HandbookDetail = () => {
  const { id } = useParams<{ id: string }>();

  const [height, setHeight] = useState('100vh');
  const heightRef = useRef<HTMLDivElement | null>(null);

  const data = HANDBOOK_LIST && HANDBOOK_LIST.find((item) => item.id === +id);

  useEffect(() => {
    if (heightRef.current) {
      const top = heightRef.current.getBoundingClientRect().y;
      const screenHeight = window.innerHeight;
      setHeight(`${screenHeight - top}px`);
    }
  }, []);

  useEffect(() => {
    if (data?.title) {
      logEvent('watee_view_handbook', {
        title: data?.title,
      });
    }
  }, [data?.title]);

  return (
    <section className='section section-bg onboarding-bg handbook-detail'>
      <SafeAreaLayout className='section-container'>
        <BackButton pathName='handbook' title='' />
        <div ref={heightRef} />
        <div style={{ height }} className='handbook-detail-block tw-pb-10 tw-overflow-auto'>
          <div className='handbook-detail-title fs-24 color-primary font-weight-600 tw-mb-8'>{data?.title}</div>
          <div className='handbook-detail-content'>
            {data?.answers?.map((answer) => (
              <div key={answer.id}>
                <p className='font-weight-600 tw-text-[#DA8BF3] fs-18 tw-mb-3'>{answer.title}</p>
                <p
                  dangerouslySetInnerHTML={{ __html: answer.content }}
                  className='tw-mb-4 font-weight-400 fs-16 color-primary tw-leading-[22.4px]'
                />
              </div>
            ))}
          </div>
        </div>
      </SafeAreaLayout>
    </section>
  );
};

export default HandbookDetail;
