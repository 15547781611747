import { Dialog, Switch } from '@happymini/react-ui';
import { useCallback, useState } from 'react';
import { useStoreState } from 'src/store';
import HappyMini from '@happymini/core';
import employee from 'src/api/employee';
import { toast } from 'src/utils/utilities';

const NotificationSwitch = () => {
  const [store, setStore] = useStoreState();
  const [loading, setLoading] = useState(false);

  const onHandleChange = useCallback(
    async (checked: boolean) => {
      const updateEmployeeSetting = async (notiStatus: boolean) => {
        setLoading(true);
        const data = await employee.updateEmployeeSetting({ notiStatus });
        setLoading(false);
        if (!data.success) {
          return toast().error(data.message);
        }
        toast().success(data.message);
        setStore('employeeSetting', data.data);
      };
      if (checked) {
        const notiPems = await HappyMini.Notification.requestPermission();
        if (!notiPems.success) {
          const dialog = Dialog.show({
            title: 'Thông báo',
            content: 'Bạn cần cấp quyền gửi thông báo để nhận nhắc nhở từ Watee',
            closeOnAction: true,
            bodyStyle: {
              textAlign: 'center',
            },
            actions: [
              [
                {
                  key: 'cancel',
                  text: 'Bỏ qua',
                  onClick: () => {
                    dialog.close();
                  },
                },
                {
                  key: 'setting',
                  text: 'Đi đến cài đặt',
                  onClick: () => HappyMini.Device.openSettings(),
                },
              ],
            ],
          });
          return;
        }
      }
      updateEmployeeSetting(checked);
    },
    [setStore]
  );

  return (
    <Switch
      loading={loading}
      onChange={onHandleChange}
      checked={store('employeeSetting')?.notiStatus}
      uncheckedText=''
      checkedText={<img src='/images/icons/check.svg' alt='' />}
    />
  );
};

export default NotificationSwitch;
