import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { EmployeeSetting } from './typings/employee-setting';

type StoreType = {
  employeeSetting: EmployeeSetting | null;
};

const store = atom<StoreType>({
  key: 'appStore',
  default: {
    employeeSetting: null,
  },
});

export const useStoreValue = () => {
  const storeState = useRecoilValue(store);

  return (storeKey: keyof StoreType) => {
    return storeState[storeKey];
  };
};

export const useSetStoreState = () => {
  const setStoreState = useSetRecoilState(store);

  return <T extends keyof StoreType>(storeKey: keyof StoreType, value: StoreType[T]) => {
    setStoreState((prev) => ({
      ...prev,
      [storeKey]: value,
    }));
  };
};

const getReturnType = <R>(fn: (...args: any[]) => R): R => {
  return {} as R;
};

const storeValueType = getReturnType(useStoreValue);
const setStoreStateType = getReturnType(useSetStoreState);

export const useStoreState = (): [typeof storeValueType, typeof setStoreStateType] => {
  return [useStoreValue(), useSetStoreState()];
};

export default store;
