import React, { FC } from 'react';
import './style.scss';
import { Button, Mask } from '@happymini/react-ui';
import { WaterTemplate } from 'src/typings/water';

const RemoveTemplateModal: FC<{
  info: Partial<WaterTemplate>;
  onClose: CallableFunction;
  onSubmit: CallableFunction;
}> = ({ info, onClose, onSubmit }) => {
  return (
    <div className='chose-drink-modal-container'>
      <Mask onMaskClick={() => onClose()} />
      <div className='drink-success-modal'>
        <div className='tw-text-[20px] font-weight-600 tw-text-center tw-mt-[40px]'>Thông báo</div>
        <div className='tw-text-center choose-drink-modal-content'>
          <p className='tw-text-[16px] tw-text-[#fff] tw-mt-[20px]'>
            Bạn chắc chắn muốn xóa <span className='tw-text-[#DA8BF3]'>“{info.name}”</span> ra khỏi danh sách đồ uống
            yêu thích?
          </p>

          <div className='drink-button tw-mt-[30px]'>
            <Button onClick={() => onClose()} className='drink-button-cancel' style={{}}>
              Hủy
            </Button>
            <Button onClick={() => onSubmit(info)} className='drink-button-continue tw-ml-2'>
              Xác nhận
            </Button>
          </div>
        </div>
        <div onClick={() => onClose()} className='modal-close'>
          <img src='/images/drink/drink-close-icon.svg' alt='' />
        </div>
      </div>
    </div>
  );
};

export default RemoveTemplateModal;
