import { Routes } from '@topcv/react-router-map';
import { RouteNames } from 'src/constants/routeNames';
import OnboardingLayout from 'src/layouts/OnboardingLayout';
import OnboardingGender from 'src/pages/Onboarding/Gender';
import OnboardingStarter from 'src/pages/Onboarding/Starter';
import OnboardingWeight from 'src/pages/Onboarding/Weight';
import Setting from 'src/pages/Setting';
import CheckSettingState from './middlewares/CheckSettingState';
import OnboardingSetting from 'src/pages/Onboarding/Setting';
import Home from 'src/pages/Home';
import IntroduceWater from '../pages/Drink/Introduce';
import Drink from 'src/pages/Drink';
import HistoryDetail from '../pages/History/Detail';
import History from '../pages/History';
import HandbookDetail from '../pages/Handbook/Detail';
import Handbook from '../pages/Handbook';

const appRoutes: Routes = [
  {
    path: 'onboarding',
    component: OnboardingLayout,
    children: [
      {
        path: '',
        exact: true,
        component: OnboardingStarter,
        name: RouteNames.ONBOARDING_HOME,
      },
      {
        path: 'gender',
        component: OnboardingGender,
        name: RouteNames.ONBOARDING_GENDER,
      },
      {
        path: 'weight',
        component: OnboardingWeight,
        name: RouteNames.ONBOARDING_WEIGHT,
      },
      {
        path: 'setting',
        component: OnboardingSetting,
        name: RouteNames.ONBOARDING_SETTING,
      },
    ],
  },
  {
    path: 'app',
    children: [
      {
        path: 'home',
        component: Home,
        name: RouteNames.APP_HOME,
      },
      {
        path: 'drink',
        children: [
          {
            path: 'intro',
            name: RouteNames.APP_DRINK_INTRO,
            component: IntroduceWater,
          },
          {
            path: '',
            component: Drink,
            name: RouteNames.APP_DRINK,
          },
        ],
      },
      {
        path: 'setting',
        component: Setting,
        name: RouteNames.APP_SETTING,
      },
      {
        path: 'history',
        children: [
          {
            path: ':date',
            component: HistoryDetail,
            name: RouteNames.APP_HISTORY_DETAIL,
          },
          {
            path: '',
            component: History,
            name: RouteNames.APP_HISTORY,
          },
        ],
      },
      {
        path: 'handbook',
        children: [
          {
            path: ':id',
            component: HandbookDetail,
            name: RouteNames.APP_HANDBOOK_DETAIL,
          },
          {
            path: '',
            component: Handbook,
            name: RouteNames.APP_HANDBOOK,
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: CheckSettingState,
  },
];

export default appRoutes;
