import { Loading } from '@happymini/react-ui';
import { PullStatus } from '@happymini/react-ui/es/components/pull-to-refresh';
import viVN from '@happymini/react-ui/es/locales/vi-VN';

export const statusRecord: Record<PullStatus, JSX.Element | string> = {
  pulling: viVN.PullToRefresh.pulling,
  canRelease: viVN.PullToRefresh.canRelease,
  refreshing: <Loading color='#fff' />,
  complete: viVN.PullToRefresh.complete,
};
