import React, { useEffect, useRef, useState } from 'react';
import BlockList from '../../components/BlockList';
import { HANDBOOK_LIST } from '../../constants/handbook';
import BackButton from '../../components/BackButton';
import SafeAreaLayout from 'src/layouts/SafeAreaLayout';
import { RouteNames } from '../../constants/routeNames';

const Handbook = () => {
  const [height, setHeight] = useState('100vh');
  const heightRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (heightRef.current) {
      const top = heightRef.current.getBoundingClientRect().y;
      const screenHeight = window.innerHeight;
      setHeight(`${screenHeight - top - 20}px`);
    }
  }, []);

  return (
    <section className='section section-bg onboarding-bg handbook'>
      <SafeAreaLayout className='section-container'>
        <BackButton pathName='setting' title='Cẩm nang' />
        <div ref={heightRef} />
        <div style={{ height }} className='setting-block tw-overflow-auto'>
          {HANDBOOK_LIST.map((handbook) => (
            <BlockList
              key={handbook.id}
              id={handbook.id}
              contentLeft={handbook.contentLeft}
              title={handbook.title}
              contentRight={handbook.contentRight}
              navigate={RouteNames.APP_HANDBOOK_DETAIL}
            />
          ))}
        </div>
      </SafeAreaLayout>
    </section>
  );
};

export default Handbook;
