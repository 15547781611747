import { FC, PropsWithChildren } from 'react';
import HappyMini from '@happymini/core';
import { SafeArea } from '@happymini/react-ui';

const statusBarHeight = HappyMini.StatusBar.height;

const SafeAreaLayout: FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => {
  return (
    <div className={className} style={{ paddingTop: statusBarHeight + 16 }}>
      {children}
      <SafeArea position='bottom' />
    </div>
  );
};

export default SafeAreaLayout;
