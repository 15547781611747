import React, { FC } from 'react';
import clsx from 'classnames';

const EmptyData: FC<{ title?: string; showImage?: boolean; className?: string }> = ({
  title = 'Chưa có dữ liệu để hiển thị',
  showImage,
  className,
}) => {
  return (
    <div className={clsx('tw-text-center tw-text-white', className)}>
      {showImage && <img width={96} className='tw-mb-[10px]' src='/images/drink/empty-water.png' />}
      <p className='tw-opacity-[0.7] tw-text-[15px] font-[400]'>{title}</p>
    </div>
  );
};

export default EmptyData;
