import { GenderType } from 'src/constants';
import { useStoreState } from 'src/store';
import clsx from 'classnames';
import { useCallback, useState } from 'react';
import { SpinLoading } from '@happymini/react-ui';
import employee from 'src/api/employee';
import { toast } from 'src/utils/utilities';

const ChooseGender = () => {
  const [store, setStore] = useStoreState();
  const [loading, setLoading] = useState(false);

  const gender = store('employeeSetting')?.gender;

  const updateGender = useCallback(
    async (genderType: number) => {
      if (genderType !== gender) {
        setLoading(true);
        const data = await employee.updateEmployeeSetting({ gender: genderType });
        if (!data.success) {
          return toast().error(data.message);
        }
        setLoading(false);
        toast().success(data.message);
        setStore('employeeSetting', data.data);
      }
    },
    [gender, setStore]
  );

  return (
    <div className='tw-h-[32px] tw-flex tw-items-center'>
      {loading ? (
        <SpinLoading color={'#fff'} style={{ '--size': '22px' }} />
      ) : (
        <div className='tw-rounded-[6px] tw-flex select-gender tw-items-center'>
          <div
            onClick={() => updateGender(GenderType.female)}
            className={clsx(gender == GenderType.female && 'active')}
          >
            Nữ
          </div>
          <div onClick={() => updateGender(GenderType.male)} className={clsx(gender == GenderType.male && 'active')}>
            Nam
          </div>
        </div>
      )}
    </div>
  );
};

export default ChooseGender;
