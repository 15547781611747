import React, { useEffect, useState } from 'react';
import DotStep from 'src/components/DotStep';
import Title from 'src/components/Title';
import { Button, Dialog, Switch, Toast } from '@happymini/react-ui';
import HappyMini from '@happymini/core';
import { RouteNames } from 'src/constants/routeNames';
import employee from 'src/api/employee';
import { useRecoilValue } from 'recoil';
import { genderState } from '../Gender/state';
import { weightState } from '../Weight/state';
import { Nav } from 'src/router';
import { toast } from 'src/utils/utilities';
import { useSetStoreState } from 'src/store';
import { logEvent } from 'src/plugins/firebase';

const Setting = () => {
  const [notiStatus, setNotiStatus] = useState(true);
  const gender = useRecoilValue(genderState);
  const weight = useRecoilValue(weightState);
  const setStore = useSetStoreState();

  const setEmployeeSetting = async () => {
    logEvent('watee_setup_noti_onboarding');
    const scopeToast = Toast.show({
      icon: 'loading',
      duration: 99999999,
      maskClickable: false,
      maskStyle: {
        background: 'rgba(0, 0, 0, .2)',
      },
    });
    const data = await employee.setEmployeeSetting({
      gender,
      notiStatus,
      weight,
    });
    scopeToast.close();
    if (!data.success) {
      return toast().error(data.message);
    }
    setStore('employeeSetting', data.data);
    logEvent('watee_success_onboarding');
    Nav.replace(RouteNames.APP_HOME);
  };

  const checkNotiStatus = async () => {
    const notiStatus = await HappyMini.Notification.requestPermission();
    setNotiStatus(notiStatus.success);
  };

  useEffect(() => {
    checkNotiStatus();
  }, []);

  const onChangeNotiStatus = async (checked: boolean) => {
    const notiStatus = await HappyMini.Notification.requestPermission();
    if (checked && !notiStatus.success) {
      const dialog = Dialog.show({
        title: 'Thông báo',
        content: 'Bạn cần cấp quyền gửi thông báo để nhận nhắc nhở từ Watee',
        closeOnAction: true,
        bodyStyle: {
          textAlign: 'center',
        },
        actions: [
          [
            {
              key: 'cancel',
              text: 'Bỏ qua',
              onClick: () => {
                dialog.close();
              },
            },
            {
              key: 'setting',
              text: 'Đi đến cài đặt',
              onClick: () => HappyMini.Device.openSettings(),
            },
          ],
        ],
      });
      return;
    }
    setNotiStatus(checked);
  };

  return (
    <>
      <img
        onClick={() => {
          Nav.pop(RouteNames.ONBOARDING_WEIGHT);
        }}
        src='/images/back.svg'
        alt=''
      />
      <DotStep keyActive={3} />
      <Title
        title='Cài đặt thông báo'
        description='Hãy chọn cài đặt mở thông báo để chúng tôi gửi lời nhắc đến cho bạn'
      />
      <div className='mt-60'>
        <div className='onboarding-setting-switch tw-flex tw-justify-between tw-items-center'>
          <div className='color-primary fs-16'>Nhận thông báo</div>
          <Switch
            onChange={onChangeNotiStatus}
            checked={notiStatus}
            checkedText={<img src='/images/icons/check.svg' alt='' />}
          />
        </div>
      </div>
      <div className='hp-button'>
        <Button onClick={setEmployeeSetting}>Tiếp tục</Button>
      </div>
    </>
  );
};

export default Setting;
