import React from 'react';
import { BlockItemType } from '../typings/setting';

const contentLeft: any = <img src='/images/handbook/question.svg' className='tw-pr-3.5' alt='' />;
const contentRight: any = (
  <img src='/images/handbook/arrow-right.svg' className='tw-ml-2' style={{ marginLeft: '10px' }} alt='' />
);

export const HANDBOOK_LIST: BlockItemType[] = [
  {
    id: 1,
    contentLeft,
    title: 'Mệt mỏi, mất tập trung - Cơ thể bạn đang "khát" đó!',
    contentRight,
    answers: [
      {
        id: 100,
        title: '',
        content: `
        <p class=''>
          Bạn cần sự tập trung cao độ để hoàn thành các công việc quan trọng mỗi ngày. Nhưng sự mệt mỏi, mất tập trung khiến bạn không thể bắt tay vào giải quyết công việc một cách nhanh chóng. Cảm giác mỏi mệt và thiếu tập trung sẽ là một trong những lý do gây ảnh hưởng trực tiếp tới hiệu suất của bạn tại nơi làm việc.
          Cùng nhau tìm ra những nguyên nhân dẫn tới tình trạng này để kịp thời “vực dậy tinh thần” và lấy lại năng lượng trong công việc nhé!
        </p>
`,
      },
      {
        id: 101,
        title: '1. Nguyên nhân dẫn đến tình trạng mệt mỏi, thiếu tập trung',
        content: `
        <ul class='font-weight-400 tw-mt-2'>
          Những biểu hiện cơ bản đang “tố cáo” một cơ thể thiếu sức sống và não bộ thiếu tập trung có thể gặp bao gồm:
          <li class='tw-ml-5'>Thường xuyên đau đầu, thiếu năng lực thể chất </li>
          <li class='tw-ml-5'>Mệt mỏi, căng thẳng, tâm trạng thất thường</li>
          <li class='tw-ml-5'>Trí nhớ suy giảm, khả năng sáng tạo bị hạn chế</li>
          <li class='tw-ml-5'>Khó đưa ra quyết định hay tập trung cho các nhiệm vụ phức tạp</li>
          <li class='tw-ml-5'>Khó ngồi yên và dễ nhầm lẫn, bỏ quên công việc</li>
        </ul>

        <div class='tw-mt-2'>
          Có rất nhiều nguyên nhân để tìm ra căn nguyên của tình trạng mệt mỏi, thiếu tập trung. Điều đầu tiên phải kể đến các vấn đề về bệnh lý khiến não bộ suy giảm chức năng, dẫn tới suy giảm trí nhớ và sự tập trung. Những người mắc các bệnh như thận/phổi mãn tính, gan, tổn thương não sau tai nạn, rối loạn sụt giảm sự chú ý, bệnh mất ngủ, rối loạn trầm cảm sẽ dễ cảm thấy mất năng lượng, hay quên do thiếu Oxy lên não.
          <img src='/images/handbook/hb-1.jpg' alt='' class='tw-w-full tw-mt-2'>
        </div>

        <ul class='font-weight-400 tw-mt-2'>
          Ngoài ra, những ảnh hưởng từ thói quen xấu trong lối sống cũng sẽ khiến sức khỏe cơ thể bạn trở nên giảm sút trầm trọng, không thể chú tâm và dồn hết sức lực cho bất cứ nhiệm vụ nào:
          <li class='tw-ml-5'>Ngủ quá ít, ngủ không đủ giấc</li>
          <li class='tw-ml-5'>Chế độ ăn uống không hợp lý, thiếu dinh dưỡng</li>
          <li class='tw-ml-5'>Lạm dụng caffeine, rượu bia và các chất kích thích</li>
          <li class='tw-ml-5'>Giữ cảm xúc tiêu cực, lo âu trong thời gian dài</li>
          <li class='tw-ml-5'>Làm việc quá sức, quá tải vì gánh nặng công việc</li>
          <li class='tw-ml-5'>Sử dụng nhiều ánh sáng xanh nhiều trong ngày và trước khi đi ngủ</li>
        </ul>

        <div class='tw-mt-2'>
          Đặc biệt, đối với nhân viên văn phòng - những người dành tối đa thời gian tại công ty, hàng ngày làm việc trước màn hình máy tính thì một trong những thói quen tốt nhưng thường xuyên bị bỏ quên đó là: Cung cấp đủ lượng nước cần cho cơ thể.
          Đây cũng là một trong những nguyên nhân chính gây ra trạng thái mệt mỏi, thiếu tập trung trong thời gian dài.
        </div>
`,
      },
      {
        id: 102,
        title: '2. “Lười uống nước” - Một những lý do tiềm ẩn khiến cơ thể kiệt sức',
        content: `
        <div class='tw-mt-2'>
          Nhiều người vì quá bận rộn cho các cuộc họp, công việc cần phải xử lý tại công ty mà “ngó lơ” cơn khát trong mình.
        </div>

        <ul class='font-weight-400 tw-mt-2'>
        Một lần, hai lần rồi nhiều lần, chúng ta nghĩ rằng sẽ bổ sung nước vào một thời điểm khác sau khi mọi nhiệm vụ đã được hoàn thành. Nhưng vô hình chung, suy nghĩ đó dần tạo ra thói quen và khiến bộ não xem nhẹ tầm quan trọng của việc uống nước đúng và uống nước đủ.
          <li class='tw-ml-5'>Thay vì bổ sung nước lọc, bạn lựa chọn cà phê hay những loại thức uống tăng lực, đồ uống có gas, đường hóa học để “đánh thức” sự tỉnh táo. </li>
          <li class='tw-ml-5'>Và trong nhiều trường hợp khác, mỗi khi cảm nhận được trạng thái mệt mỏi, mất tập trung trong quá trình làm việc, bạn ngay lập tức suy nghĩ là do mình ăn chưa đủ và lựa chọn ăn thay vì nạp thêm nước cho cơ thể.</li>
        </ul>

        <img src='/images/handbook/hb-2.jpg' alt='' class='tw-w-full tw-mt-2'>

        <div class='tw-italic tw-mt-2'>
          Theo Nghiên cứu từ Đại học Loughborough, con người đang uống ít nước hơn so với nhu cầu cần được cung cấp. Và một nghiên cứu tại Anh đã cảnh báo số người phải nhập viện vì các bệnh liên quan đến việc thiếu nước đang có tỷ lệ tăng cao đột biến.
        </div>

        <div class='tw-mt-2'>
          Đặc biệt, đối với nhân viên văn phòng - những người dành tối đa thời gian tại công ty, hàng ngày làm việc trước màn hình máy tính thì một trong những thói quen tốt nhưng thường xuyên bị bỏ quên đó là: Cung cấp đủ lượng nước cần cho cơ thể.
          Đây cũng là một trong những nguyên nhân chính gây ra trạng thái mệt mỏi, thiếu tập trung trong thời gian dài.
        </div>

        <div class='tw-mt-2'>
        Báo cáo chỉ ra những lý do dẫn đến việc lười uống nước, cụ thể như:
        </div>

        <div>
          <div class='font-weight-700 tw-mt-2'>
            Không nhận thức được tầm quan trọng của thói quen bổ sung nước
          </div>
          <div>
          Khi chưa nhìn rõ được những vai trò và lợi ích nước mang lại cho cơ thể, chúng ta sẽ chỉ uống khi thực sự cảm thấy khát thay vì biến nó thành một thói quen gần gũi hàng ngày.
          </div>
        </div>

         <div>
          <div class='font-weight-700 tw-mt-2'>
            Lười di chuyển và lười đi vệ sinh
          </div>
          <div>
          Một trong những nguyên nhân phổ biến khiến mọi người bao gồm nhân viên văn phòng lười uống nước vì sợ tốn thời gian và “ngại” đi vệ sinh. Họ sẽ chủ động nhịn uống nước hoặc hạn chế uống nhất có thể để giảm số lần đi vệ sinh.
          </div>
        </div>

        <div>
          <div class='font-weight-700 tw-mt-2'>
            Tính chất công việc bận rộn
          </div>
          <div>
          Với những tổ chức đòi hỏi cường độ làm việc cao, khối lượng công việc quá nhiều khiến nhân viên vô tình quên nhiệm vụ uống nước. Sự quá tải tạo ra căng thẳng, stress, khiến cơ thể không còn cảm nhận được trạng thái “khát” hay đang thiếu nước.
          </div>

          <img src='/images/handbook/hb-3.jpg' alt='' class='tw-w-full tw-mt-2'>
        </div>

        <ul class='font-weight-400 tw-mt-2'>
        “Lười uống nước” đã trở thành một “căn bệnh” nghiêm trọng và mang tính chất cảnh tỉnh, đặc biệt đối với những người làm văn phòng. Nước không được bổ sung đủ vào cơ thể làm tăng nguy cơ ảnh hưởng đến hoạt động của não bộ, hệ tiêu hóa, hệ bài tiết và nhiều cơ quan quan trọng khác, dẫn đến những tình trạng:
          <li class='tw-ml-5'>Đau mỏi xương khớp</li>
          <li class='tw-ml-5'>Hoa mắt, chóng mặt</li>
          <li class='tw-ml-5'>Mệt mỏi, mất tập trung</li>
          <li class='tw-ml-5'>Mắt khô, nhạy cảm, dễ nhiễm khuẩn</li>
          <li class='tw-ml-5'>Suy giảm chức năng thận</li>
        </ul>

        <div class='tw-mt-2 tw-text-center tw-italic'>
          Một khảo sát nhỏ cho thấy, mỗi năm thế giới thiệt hại tới 2,5 tỷ USD
          chỉ vì dân văn phòng uống không đủ nước.
        </div>

        <div class='tw-mt-2'>
          Những nhân viên với thói quen uống nước quá ít sẽ luôn rơi vào trạng thái làm việc mệt mỏi. Không chỉ ảnh hưởng đến sự tập trung và hiệu suất công việc, nó cũng khiến cho môi trường làm việc xung quanh và tâm trạng của những đồng nghiệp trong tổ chức bị ảnh hưởng.
        </div>
`,
      },
      {
        id: 103,
        title: '3. Hình thành thói quen uống nước để “sạc” đủ năng lượng mỗi ngày',
        content: `
        <ul class='font-weight-400 tw-mt-2'>
          Cũng như cây cối, muốn tươi tốt đều cần được tưới nước và chăm sóc mỗi ngày. Bạn hãy lắng nghe cơ thể mình để tạo lập và duy trì những thói quen tốt, bao gồm việc uống nước.
          <li class='tw-ml-5'>Từ hôm nay, hãy lên kế hoạch cho mỗi ngày làm việc uống được ít nhất 1,5 lít nước </li>
          <li class='tw-ml-5'>Thực hiện điều đó bằng cách đặt một cốc nước hoặc bình đựng nước có định lượng rõ ràng trong tầm mắt ngay trên bàn làm việc</li>
          <li class='tw-ml-5'>Bổ sung kết hợp các loại thực phẩm chứa nhiều nước để cảm thấy ngon miệng và dễ dàng hơn trong việc hấp thu đủ lượng nước cần cho cơ thể trong ngày</li>
        </ul>

        <img src='/images/handbook/hb-4.jpg' alt='' class='tw-w-full tw-mt-2'>

        <div class='tw-mt-2'>
        Sức khỏe vẫn luôn là điều quan trọng, giúp chúng ta có thêm thời gian, tinh thần, thể chất để chinh phục mọi kế hoạch, ước mơ trong cuộc sống. Chính vì thế hãy luôn đầu tư và đề cao sức khỏe của bản thân.
        </div>

        <div class='tw-mt-2'>
        Cảm thấy mệt mỏi, thiếu tập trung, quan sát cơ thể và cùng đồng nghiệp bổ sung nước như một thói quen ngay từ hôm nay, bạn nhé!
        </div>
`,
      },
    ],
  },
  {
    id: 2,
    contentLeft,
    title: 'Điều gì xảy ra nếu chúng ta bổ sung thiếu hoặc thừa nước?',
    contentRight,
    answers: [
      {
        id: 200,
        title: '',
        content: `
            Tình trạng bổ sung thiếu nước hoặc thừa nước trong cơ thể đều có khả năng gây ra những tác động không tốt lên sức khỏe. Phần lớn chúng ta đều được cảnh báo về sự nguy hiểm của việc uống quá ít nước. Trong khi đó ít người biết rằng uống quá nhiều nước cũng gây ra những hậu quả nghiêm trọng không kém.
          `,
      },
      {
        id: 201,
        title: '1. Điều gì xảy ra nếu cơ thể thiếu nước?',
        content: `
            <div>
            Khi lượng nước nạp vào ít hơn lượng nước được thải ra, cơ thể có nguy cơ mất nước dẫn đến cản trở các quá trình trao đổi chất giữa các cơ quan.
</div>

<ul class='font-weight-400 tw-mt-2'>
Tình trạng này dễ gặp ở những người:
<li class='tw-ml-5'>Không có thói quen uống nước thường xuyên</li>
<li class='tw-ml-5'>Cần vận động, lao động mạnh, di chuyển nhiều do tính chất công việc (vận động viên thể thao, nhân viên làm việc ngoài trời,...)</li>
<li class='tw-ml-5'>Người bệnh ăn uống kém, đang sử dụng các loại thuốc hỗ trợ lợi tiểu, nhuận tràng</li>
</ul>

<ul class='font-weight-400 tw-mt-2'>
Khi gặp những dấu hiệu sau đây cũng là lúc bạn cần chú ý bổ sung thêm nước vào cơ thể:
<li class='tw-ml-5'>Cảm thấy khô họng, khát nước thường xuyên</li>
<li class='tw-ml-5'>Da khô sạm, thiếu sức sống</li>
<li class='tw-ml-5'>Đi tiểu ít, nước tiểu có màu vàng đậm và đặc</li>
</ul>

<img src='/images/handbook/hb-5.jpg' alt='' class='tw-w-full tw-mt-2'>

<div class='tw-mt-2'>Chính từ thói quen không chịu cung cấp đủ nước cho cơ thể, theo thời gian tình trạng này sẽ gây ra những biến chứng từ nhẹ đến nghiêm trọng như:</div>

<div class='tw-mt-2'>
<span class='font-weight-700'>Thiếu nước ở mức độ nhẹ:</span> Cơ thể luôn trong trạng thái buồn ngủ, mệt mỏi, tiêu hóa kém, tâm trạng thay đổi thất thường và dẫn đến đau các khớp. Làn da của bạn cũng bị ảnh hưởng khi ngày càng nhăn nheo và dễ khô sạm.
</div>
<div class='tw-mt-2'>
<span class='font-weight-700'>Thiếu nước ở mức độ nặng: </span> Bỏ quên việc uống đủ nước trong một thời gian dài sẽ gây ra những hệ lụy như huyết áp thấp, tim đập nhanh, nhiều trường hợp có thể mê sảng, bất tỉnh hoặc tiêu chảy, buồn nôn do bị mất nước trầm trọng.
</div>

<div class='tw-mt-2'>
Ngoài ra, bổ sung thiếu nước sẽ làm chậm quá trình trao đổi chất trong cơ thể, giảm khả năng loại bỏ các chất thải hay độc tố, khiến bạn cảm thấy nhanh đói và dễ tăng - giảm nhiệt cơ thể một cách bất thường. Một số ít trường hợp đã dẫn đến tử vong do mất nước nghiêm trọng mà không có biện pháp bổ sung kịp thời.
</div>
`,
      },
      {
        id: 202,
        title: '2. Điều gì xảy ra nếu cơ thể thừa nước?',
        content: `

<ul class='font-weight-400 tw-mt-2'>
Việc thừa nước trong cơ thể cũng rất nguy hiểm và đáng được lưu ý không kém so với tình trạng mất nước. Thừa nước thường được biểu hiện qua hai dạng:
<li class='tw-ml-5'>Lượng nước nạp vào nhiều hơn mức cần: Đây là tình trạng bạn uống liên tục và uống nhiều nước hơn so với lượng mà thận có thể thải ra qua nước tiểu. Khi đó, nước sẽ không thể đào thải ra bên ngoài dẫn đến việc tích tụ quá nhiều nước trong cơ thể.</li>
<li class='tw-ml-5'>Cơ thể bị giữ nước: Điều này xảy ra khi bạn mắc một số loại bệnh như: gan, thận, suy tim xung huyết, hội chứng hormone lợi tiểu không phù hợp, khiến lượng nước thừa không thể được thải ra như bình thường, nước tiếp tục bị giữ lại và tích tụ.</li>
</ul>

<img src='/images/handbook/hb-6.jpg' alt='' class='tw-w-full tw-mt-2'>

<div class='tw-mt-2'>Khó phân biệt do ít triệu chứng hơn so với thiếu nước, thừa nước có thể khiến bạn cảm thấy đầy bụng, căng tức và gặp một số dấu hiệu như chóng mặt, buồn nôn, đau đầu hoặc mất phương hướng.</div>

<div class='tw-mt-2'>
Vì thế, chúng ta phải đặc biệt quan tâm đến tình trạng bổ sung nước của bản thân, chỉ nên bổ sung phù hợp và tránh suy nghĩ: “Uống càng nhiều thì càng tốt cho cơ thể!” <br>
Trong trường hợp lượng nước bị dư thừa quá nhiều, lượng muối trong máu bị giảm đột ngột tăng khả năng dẫn đến đau nhức cơ, chuột rút, co giật, bất tỉnh hoặc hôn mê.
</div>
`,
      },
      {
        id: 203,
        title: '3. Khỏe mạnh là khi chúng ta hiểu cơ thể thực sự đang cần gì',
        content: `

<ul class='font-weight-400 tw-mt-2'>
Từ những chia sẻ trên, chúng ta có thể thấy: Thiếu nước hay thừa nước đều gây ra những biến chứng nguy hiểm, thậm chí ảnh hưởng trực tiếp lên sức khỏe và tính mạng con người.
<li class='tw-ml-5'>Nếu cảm nhận được mình đang bổ sung quá ít nước, bạn có thể bắt đầu thói quen này ngay tại thời điểm này bằng cách thiết lập thời gian biểu cho việc uống nước mỗi ngày.</li>
<li class='tw-ml-5'>Khi nhận ra mình gặp các dấu hiệu thừa nước hay lạm dụng việc uống nước nhiều vì thiếu kiến thức liên quan tới sức khỏe, trước khi duy trì thói quen này quá lâu, bạn có thể thực hiện các xét nghiệm đánh giá mất cân bằng nước để xác định rõ xem mình cần bổ sung thêm bao nhiêu nước là đủ.</li>
</ul>

<img src='/images/handbook/hb-7.jpeg' alt='' class='tw-w-full tw-mt-2'>

<div class='tw-mt-2'>
Đừng trì hoãn để thay đổi những thói quen xấu trước khi chúng trở thành “con dao hai lưỡi” đe dọa đến sức khỏe thể chất và tinh thần của bạn. Hãy chủ động lắng nghe cơ thể và bổ sung nước đúng và đủ với nhu cầu cần thiết, bạn sẽ trở nên khỏe mạnh và tràn đầy năng lượng mỗi ngày!
</div>
`,
      },
    ],
  },
  {
    id: 3,
    contentLeft,
    title: 'Mối liên hệ giữa nước với sức khỏe thể chất và tinh thần',
    contentRight,
    answers: [
      {
        id: 300,
        title: '',
        content: `
          Sức khỏe thể chất và sức khỏe tinh thần luôn là hai khái niệm gắn liền và bổ trợ cho nhau. Để có được một cơ thể khỏe mạnh, một trong những việc quan trọng chúng ta cần quan tâm đó là đảm bảo cung cấp đủ lượng nước cho cơ thể.
Khi sức khỏe thể chất được quan tâm đúng cách, bạn sẽ luôn giữ được tinh thần và trạng thái tỉnh táo, ít gặp các bệnh về tâm lý như lo lắng, trầm cảm hay đối mặt với những suy nghĩ thiếu lành mạnh.
          `,
      },
      {
        id: 301,
        title: '1. Mối liên hệ giữa nước và sức khỏe thể chất',
        content: `
          <div class='tw-mt-2'>Chiếm tỷ lệ tới 70% trọng lượng cơ thể, nước là một trong những thành phần cơ bản nuôi dưỡng sự sống của con người.</div>

          <img src='/images/handbook/hb-8.jpg' alt='' class='tw-w-full tw-mt-2'>
<ul class='font-weight-400 tw-mt-2'>
<div class='font-weight-700'>4 vai trò chính của nước đối với sức khỏe:</div>
<li class='tw-ml-5'>Đóng vai trò cung cấp nguồn khoáng chất, vận chuyển chất dinh dưỡng, oxy cần thiết và nuôi dưỡng các tế bào trong mọi hoạt động của cơ thể</li>
<li class='tw-ml-5'>Là chất tham gia trực tiếp vào các phản ứng khác nhau của cơ thể, bao gồm chuyển đổi thức ăn thành năng lượng, đào thải độc tố, các chất cặn bã mà cơ thể không thể hấp thu được thông qua hệ tiêu hóa</li>
<li class='tw-ml-5'>Giữ vai trò bôi trơn các khớp xương là nơi tiếp xúc các đầu nối, bao hoạt dịch và màng bao, giúp xương khớp hoạt động nhịp nhàng, trơn tru, tránh tổn thương trong quá trình vận động</li>
<li class='tw-ml-5'>Có tác dụng điều hòa thân nhiệt cân bằng ở ngưỡng 37 độ C. Nhờ có nước, trong điều kiện thời tiết thay đổi nóng, lạnh, thân nhiệt của chúng ta sẽ tự động điều chỉnh để cân bằng và thích nghi</li>
</ul>

<div class='tw-mt-2'>
Nước mang đến nhiều lợi ích cho con người. Vì thế uống nước đúng và đủ mỗi ngày sẽ giúp duy trì và cải thiện sức khỏe, ngăn cơ thể chúng ta trước nguy cơ mắc các bệnh như đau đầu, suy giảm thể lực, sỏi tiết niệu, táo bón, béo phì,...
</div>
`,
      },
      {
        id: 302,
        title: '2. Mối liên hệ giữa nước và sức khỏe tinh thần',
        content: `
          <div class='tw-mt-2'>Không chỉ ảnh hưởng trực tiếp đến sức khỏe thể chất, mối liên hệ giữa nước và sức khỏe tinh thần cũng vô cùng chặt chẽ. Thiếu nước gây nên những tác động tiêu cực tới tinh thần vì não bộ là phần chính quyết định cảm xúc và trạng thái năng lượng của mỗi chúng ta.</div>

<div class='tw-mt-2'>
73% não bộ và tim của con người được cấu thành từ nước. Khi không cung cấp đủ lượng nước, các hệ thống trong cơ thể bắt đầu trở nên trì trệ và không thể hoạt động, phản ứng như bình thường.
</div>
<div class='tw-mt-2'>
Đặc biệt trong thời đại ngày nay, giữa những áp lực công việc, cuộc sống, căn bệnh trầm cảm ngày càng trở nên phổ biến và lan rộng. Nếu không đáp ứng được những nhu cầu cần cho cơ thể, bạn sẽ rất dễ đối mặt với hội chứng này. Mặc dù mất nước không trực tiếp gây ra những triệu chứng ở căn bệnh trầm cảm, nhưng không uống đủ nước trong một thời gian dài thì những rối loạn, lo âu hoàn toàn có thể hình thành và tạo ra những ảnh hưởng nghiêm trọng.
</div>
<img src='/images/handbook/hb-9.jpg' alt='' class='tw-w-full tw-mt-2'>
<div class='tw-mt-2'>
Uống nhiều và đủ nước là một cách hỗ trợ não bộ làm tốt nhiệm vụ của nó. Bạn trao cho não bộ quyền thực hiện tốt mọi chức năng, giữ cho cơ thể khỏe mạnh và một trạng thái tinh thần cân bằng, lành mạnh. Cơ thể vận hành tốt đồng thời sẽ là nền tảng để cải thiện sức khỏe tinh thần theo thời gian.
</div>
<div class='tw-mt-2'>
Bắt đầu ngày mới bằng một ly nước và hình thành thói quen uống nước đúng cách sẽ là công thức đơn giản giúp duy trì sự khỏe mạnh, vui tươi và một tinh thần hứng khởi, nâng cao năng lượng và hiệu suất làm việc trong cả ngày dài.
</div>
`,
      },
    ],
  },
  {
    id: 4,
    contentLeft,
    title: 'Bí quyết giúp dân văn phòng luôn tràn đầy năng lượng',
    contentRight,
    answers: [
      {
        id: 400,
        title: '',
        content: `
<div class='tw-mt-2'>Để công việc diễn ra suôn sẻ và hiệu quả, chúng ta cần có một thể trạng tốt, khả năng tập trung cao độ và một tinh thần nhiệt huyết, hứng khởi. Tuy nhiên, đối với những nhân viên văn phòng làm việc liên tục trong nhiều giờ đồng hồ cùng máy tính và thường xuyên hoạt động trí não cường độ cao, cơ thể rất dễ rơi vào trạng thái mệt mỏi, uể oải và chán nản.</div>
<div class='tw-mt-2'>Đừng lo lắng! Việc tạo lập và duy trì những thói quen sau đây sẽ giúp bạn nhanh chóng vượt qua áp lực trong công việc và luôn giữ được tinh thần tràn đầy năng lượng.</div>
`,
      },
      {
        id: 401,
        title: '1. Duy trì thói quen ngủ đủ giấc vào ban đêm',
        content: `
<div class='tw-mt-2'>Một giấc ngủ đủ và ngủ ngon mang đến nhiều lợi ích. Đây sẽ là khoảng thời gian cho phép cơ thể bạn nghỉ ngơi và tái tạo lại năng lượng sau một ngày dài. Khi đó bộ não được phục hồi đưa cơ thể về trạng thái tỉnh táo và tập trung. Các dây thần kinh hoạt động tốt hơn, khuyến khích sự sáng tạo và những ý tưởng mới giúp bạn tăng hiệu quả làm việc cũng như cải thiện chỉ số cảm xúc và tinh thần.</div>
<img src='/images/handbook/hb-10.png' alt='' class='tw-w-full tw-mt-2'>
<div class='tw-mt-2'>
Tốt nhất, bạn hãy sắp xếp thời gian và công việc để ngủ đủ 7-8 tiếng mỗi đêm và nên hạn chế sử dụng các thiết bị điện tử trước khi vào giấc ngủ.
</div>
`,
      },
      {
        id: 402,
        title: '2. Ăn sáng đều đặn, bổ sung đầy đủ các nhóm chất',
        content: `
<div class='tw-mt-2'>Với quỹ thời gian ít ỏi và bận rộn vào buổi sáng, nhân viên văn phòng thường có thói quen bỏ qua bữa sáng. Tưởng chừng vô hại nhưng nếu tình trạng này tiếp diễn liên tục, việc bỏ bữa sáng sẽ gây những ảnh hưởng xấu tới sức khỏe và kéo theo nhiều bệnh lý nguy hiểm.</div>
<img src='/images/handbook/hb-11.jpg' alt='' class='tw-w-full tw-mt-2'>
<div class='tw-mt-2'>
Hãy ưu tiên bữa sáng như một bữa quan trọng nhất trong ngày, hãy ăn ngay cả khi bạn không cảm thấy đói và đừng vị sự bận rộn mà vô tình bỏ qua hay xem nhẹ nó.
</div>
<div class='tw-mt-2'>
Cung cấp đủ 4 nhóm thực phẩm bao gồm: bột đường, chất béo, đạm và vitamin vào buổi sáng hỗ trợ dạ dày hoạt động ổn định và não bộ duy trì khả năng tập trung tốt hơn.
</div>
<div class='tw-mt-2'>
Ngoài ra, đó cũng là thời điểm cơ thể phát huy khả năng hấp thụ tối đa các chất dinh dưỡng từ những loại thực phẩm như sữa, ngũ cốc, trái cây. Chăm ăn sáng khoa học giúp bạn giảm khả năng tích tụ chất béo, kiểm soát cảm giác thèm ăn tránh gây xao nhãng, ảnh hưởng đến quá trình làm việc.
</div>
`,
      },
      {
        id: 403,
        title: '3. Thường xuyên tập luyện và vận động nhẹ nhàng',
        content: `
<div class='tw-mt-2'>Một người chăm chỉ tập luyện thể chất thường giữ được trạng thái tỉnh táo và khỏe mạnh hơn những người không có thói quen này. Nếu bạn cảm thấy bản thân cần đầu tư phần lớn thời gian cho công việc, hãy thử cân nhắc một số động tác đơn giản từ 5-10 phút vào buổi sáng mỗi ngày.</div>
<div class='tw-mt-2'>
Tập thể dục giúp cơ thể sản sinh ra các hormone có lợi, đặc biệt là hormone endorphin giúp giảm căng thẳng, khiến tâm trạng vui tươi, hứng khởi và năng lượng.
</div>
<div class='tw-mt-2'>
Trong quá trình làm việc tại văn phòng, bạn cũng có thể tranh thủ đi lại hoặc thực hiện một số động tác nhẹ nhàng vào giữa giờ làm việc buổi sáng, đầu giờ chiều hoặc giữa giờ làm việc buổi chiều.
</div>
`,
      },
      {
        id: 404,
        title: '4. Cung cấp đủ nước cho cơ thể',
        content: `
<div class='tw-mt-2'>Các nghiên cứu đều chỉ ra rằng cơ thể không được bổ sung đủ lượng nước sẽ ảnh hưởng trực tiếp đến khả năng tập trung của não bộ. Tất cả các cơ quan đều cần có nước để duy trì mức độ hoạt động cao nhất. Bổ sung đủ lượng nước giúp bạn tăng cường được cả cơ thể bên ngoài lẫn yếu tố tinh thần bên trong.</div>
<div class='tw-mt-2'>
Đặc biệt trong điều kiện làm việc nhiều với máy tính và phòng máy lạnh, nước sẽ là “người bạn lành mạnh” song hành hỗ trợ tốt nhất cho sự tập trung và tỉnh táo của mỗi người. Bên cạnh đó, không chỉ cải thiện tình trạng đau mỏi cơ bắp do ngồi làm việc nhiều, uống đủ nước còn giúp khắc phục tình trạng đau mỏi mắt và khô da thường gặp ở dân văn phòng.
</div>

<img src='/images/handbook/hb-12.jpg' alt='' class='tw-w-full tw-mt-2'>

<ul class='font-weight-400 tw-mt-2'>
Ngoài ra, để giữ được một tinh thần và trạng thái tích cực trong công việc, bạn có thể chủ động thiết lập thói quen và tạo ra môi trường làm việc tích cực cho bản thân mình.
Những điều đó có thể đến từ việc:

<li class='tw-ml-5'>Giữ cho không gian bàn làm việc luôn luôn gọn gàng, sạch sẽ, nhiều cảm hứng</li>
<li class='tw-ml-5'>Đặt mục tiêu, kế hoạch công việc cần hoàn thành theo ngày, theo tuần, theo tháng</li>
<li class='tw-ml-5'>Hướng suy nghĩ tới những điều tích cực để luôn kiếm tìm thấy niềm vui tại nơi làm việc</li>
</ul>

<div class='tw-mt-2'>
Văn phòng là nơi gắn bó và dành phần lớn thời gian trong cuộc đời của dân công sở, vì thế hãy ghé thăm và trở về trong một trạng thái vui tươi, năng lượng nhất. Bằng cách rèn luyện và áp dụng những thói quen kể trên một cách đều đặn, bạn sẽ đẩy lùi sự mệt mỏi và gia tăng thêm những nguồn năng lượng tích cực trong suốt quá trình làm việc.
</div>
`,
      },
    ],
  },
  {
    id: 5,
    contentLeft,
    title: 'Duy trì thói quen uống nước vào 8 thời điểm “vàng”',
    contentRight,
    answers: [
      {
        id: 500,
        title: '',
        content: `
          <p>Uống đủ nước hàng ngày là phương pháp giúp cơ thể hấp thụ các khoáng chất và dễ dàng đào thải độc tố tích tụ trong cơ thể một cách tự nhiên. Quy tắc uống nước ứng vào 8 thời điểm “vàng” sau đây chắc chắn sẽ mang lại hiệu quả bất ngờ cho sức khỏe, vóc dáng, làn da và tinh thần của bạn.</p>
        <img src='/images/handbook/hb-13.jpg' alt='' class='tw-w-full tw-mt-2'>
        <div>
          <div class='font-weight-700 tw-mt-2'>
            Uống ngay khi vừa thức dậy: 6H - 7H30 SÁNG
          </div>
          <div>
            Khi bạn vừa trải qua một trạng thái nghỉ ngơi và không được bổ sung nước trong một thời gian dài, uống một cốc nước ấm từ 250ml - 300ml sẽ giúp đánh thức các cơ quan trong cơ thể. Đồng thời những độc tố tích tụ trong gan và thận cũng được đào thải và lọc sạch nhanh chóng. Ngoài nước ấm, bạn có thể sử dụng nước chanh mật ong để tăng cường khả năng tự miễn dịch và bổ sung hoạt chất chống oxy hóa, thúc đẩy hệ tiêu hóa.
          </div>
        </div>

        <div>
          <div class='font-weight-700 tw-mt-2'>
            Uống nước trước khi bắt đầu giờ làm việc: 8H - 9H SÁNG
          </div>
          <div>
            Sau thời gian di chuyển đến nơi làm việc, cơ thể sẽ cần một lượng nước để bù lại cho hoạt động này. Vì vậy trước khi ăn sáng và bắt tay vào công việc, bạn hãy bổ sung cốc nước thứ 2 từ 150ml - 200ml để tiếp thêm năng lượng. Nước giúp cơ thể tỉnh táo, sảng khoái, kích thích đầu óc minh mẫn cho bạn dễ dàng tập trung và làm việc hiệu quả.
          </div>
        </div>

        <div>
          <div class='font-weight-700 tw-mt-2'>
            Uống nước trong khoảng thời gian từ 10H - 11H
          </div>
          <div>
          10 giờ đến 11h giờ sáng là thời điểm trước bữa ăn trưa khoảng 30 phút - 1 tiếng. Lúc này bạn vừa trải qua một khoảng thời gian làm việc căng thẳng, tập trung cao độ. Môi trường làm việc trong điều hòa và trước màn hình máy tính có thể khiến mắt mỏi, da khô và lấy đi một phần năng lượng của bạn. Đừng quên đứng lên di chuyển, vận động cơ thể nhẹ nhàng và uống một cốc nước từ 200ml - 250ml để cấp ẩm nhé!
          </div>
        </div>

        <div>
          <div class='font-weight-700 tw-mt-2'>
            Bổ sung nước sau giờ nghỉ trưa: 13H - 14H CHIỀU
          </div>
          <div>
          Dân văn phòng thường có khoảng từ 1 tiếng đến 1 tiếng 30 phút cho các hoạt động ăn trưa và nghỉ ngơi. Quãng thời gian này khá ngắn và đôi khi khiến chúng ta cảm thấy chưa đủ để cơ thể thực sự thư giãn. Việc bắt đầu công việc vào đầu giờ chiều thường sẽ khó khăn và mất nhiều thời gian hơn vì cơn buồn ngủ vẫn liên tục kéo đến và “tấn công”, khiến bộ não rơi vào trạng thái uể oải, khó tập trung. Một ly nước mát từ 150ml - 200ml sẽ giúp bạn nhanh chóng lấy lại sự tỉnh táo và sớm quay về guồng công việc của mình.
          </div>
        </div>

        <div>
          <div class='font-weight-700 tw-mt-2'>
            Bổ sung nước vào giữa giờ làm việc buổi chiều: 15H30 - 16H30 CHIỀU
          </div>
          <div>
          Tương tự như buổi sáng, sau một khoảng thời gian làm việc từ 1 - 2 tiếng, cơ thể cũng cần được bổ sung lượng nước đã tiêu hao. Uống nước vào thời điểm này có tác dụng giảm cảm giác buồn ngủ, mệt mỏi, mất sức vì tập trung cho công việc. Bên cạnh đó đối với những nhân viên thường cảm thấy đói vào buổi chiều, việc uống khoảng 150ml - 200ml là từ 15h - 16h30 sẽ giúp bạn ít cảm thấy đói hay thèm ăn vặt.
          </div>
        </div>

        <div>
          <div class='font-weight-700 tw-mt-2'>
            Uống nước vào thời điểm kết thúc ngày làm việc: 17h30H - 18H CHIỀU
          </div>
          <div>
          Với một lượng nước tương tự như thời điểm 5, sau khi đã hoàn thành mọi công việc và nhiệm vụ trong ngày, trước khi rời công ty trở về nhà bạn đừng quên thưởng cho mình một ly nước. Lượng nước được nạp vào sẽ là nguồn năng lượng tiếp sức để bạn di chuyển về nhà, kết thúc một ngày làm việc đầy tập trung và hiệu suất. Hơn nữa, với những người đang ăn kiêng vào buổi tối, điều này giúp dạ dày không rơi vào trạng thái rỗng, đói và mệt mỏi.
          </div>
        </div>

        <div>
          <div class='font-weight-700 tw-mt-2'>
            Uống nước từ 18H30 - 20H30 TỐI
          </div>
          <div>
          Trong khung giờ này, bạn sẽ cần bổ sung nước cho nhiều hoạt động khác nhau. Đối với những người hoạt động thể chất, việc uống sẽ diễn ra liên tục trong suốt quá trình tập luyện. Lượng nước mất đi lúc này có thể bằng lượng nước tiêu hao của cả buổi trong một ngày. Khi bạn tập thể thao cơ thể sẽ tiết mồ hôi và mất sức, vì thế đừng quên uống nước như một nhiệm vụ không thể thiếu để cân bằng lại lượng nước cơ thể cần được đáp ứng.
          </div>
        </div>

        <div>
          <div class='font-weight-700 tw-mt-2'>
            Uống nước một giờ trước khi đi ngủ
          </div>
          <div>
          Bổ sung nước trước khi ngủ khoảng 1 giờ giúp phòng chống nguy cơ hình thành các cục máu đông trong cơ thể. Hoạt động bài tiết của cơ thể trong lúc ngủ khi có sự hỗ trợ từ nước sẽ được đảm bảo tốt hơn. Thời điểm này bạn chỉ nên uống một lượng nước nhỏ, tầm 100ml.
          </div>
        </div>
`,
      },
    ],
  },
  {
    id: 6,
    contentLeft,
    title: 'Nên và không nên uống nước khi nào?',
    contentRight,
    answers: [
      {
        id: 600,
        title: '',
        content: `
            <div class='tw-mt-2'>
              Như chúng ta đã biết, cơ thể người giống như một “nhà máy thủy lực” bởi khối lượng nước chiếm tới 70%, nước len lỏi và nuôi dưỡng sự sống. Vì thế ngoài việc ưu tiên bổ sung nước thường xuyên, chúng ta cần có cho mình những kiến thức về thời điểm bổ sung nước cho cơ thể sao cho phù hợp và khoa học nhất.
            </div>
            <div class='tw-mt-2 font-weight-700 tw-text-lg'>
              Một số thời điểm lý tưởng để bổ sung nước
            </div>
          `,
      },
      {
        id: 601,
        title: '1. Uống ngay sau khi vừa thức dậy',
        content: `
            <div class='tw-mt-2'>
            Hãy uống một cốc nước ấm khi vừa thức dậy vào buổi sáng. Đây là cách đơn giản giúp bạn đánh thức não bộ, loại bỏ các độc tố đang tích tụ trong cơ thể. Bổ sung nước tại thời điểm này cũng hỗ trợ hệ tiêu hóa hoạt động tốt hơn, giúp bạn luôn tỉnh táo và khỏe mạnh.
            </div>
          `,
      },
      {
        id: 602,
        title: '2. Uống nước trước khi ăn tầm 30 phút',
        content: `
            <div class='tw-mt-2'>
            Một trong những thời điểm quan trọng để bổ sung nước mà bạn không thể bỏ lỡ đó là 30 phút trước mỗi bữa ăn chính. Các chuyên gia dinh dưỡng cho rằng, uống nước trước khi ăn sẽ kích thích vị giác, giảm tình trạng nạp quá nhiều đồ ăn đến mức “quá tải” dạ dày. Đặc biệt, khi bạn thưởng thức những món ăn nhiều vị chua hoặc cay, nước sẽ giữ ẩm cho niêm mạc dạ dày tránh gây cảm giác khó chịu.
            </div>
          `,
      },
      {
        id: 603,
        title: '3. Uống nước trước và sau khi tập luyện',
        content: `
            <div class='tw-mt-2'>
            Xuyên suốt quá trình tập luyện thể chất, bất kể là trước, trong hoặc sau tập, bạn nên chia nhỏ lượng nước để luôn giữ cơ thể cân bằng giữa lượng nước nạp vào và mất đi.
            </div>
            <div class='tw-mt-2'>
            Uống nước trước khi tập luyện giúp xương khớp hoạt động trơn tru, ngăn ngừa tình trạng cơ thể mất nước khi vận động.
            </div>
            <div class='tw-mt-2'>
            Uống nước trong quá trình tập luyện sẽ giúp bạn giảm mệt mỏi, tăng hiệu quả thể chất.
            </div>
            <div class='tw-mt-2'>
            Nước được bổ sung vào cơ thể sau khi tập luyện hỗ trợ điều chỉnh thân nhiệt, hạn chế chuột rút hay giảm cân do mất nước thay vì đốt mỡ.
            </div>
          `,
      },
      {
        id: 604,
        title: '4. Uống nước trước khi tắm',
        content: `
            <div class='tw-mt-2'>
            Tắm là một quá trình tiêu tốn khá nhiều năng lượng, vì thế sẽ gây ra tình trạng mất nước. Uống nước trước khi tắm mang lại nhiều tác dụng như làm giảm huyết áp, cân bằng thân nhiệt, giúp các mạch máu giãn ra và ngăn ngừa những rủi ro từ việc cơ thể mất nước đột ngột.
            </div>
          `,
      },
      {
        id: 605,
        title: '5. Uống nước trước khi đi ngủ',
        content: `
            <div class='tw-mt-2'>
            Trong khi ngủ, quá trình hô hấp vẫn diễn ra làm lượng nước trong cơ thể bị tiêu hao. Bổ sung lượng nước vừa đủ trước khi ngủ từ 30 phút đến 1 giờ đồng hồ giúp ngăn ngừa những biến chứng tiềm ẩn về tim mạch - một trong những nguy cơ dẫn đến đột quỵ. Nước đi vào cơ thể sẽ giúp quá trình hô hấp, tuần hoàn máu tốt hơn.
            </div>
          `,
      },
      {
        id: 606,
        title: '6. Bổ sung nhiều nước khi đang bị bệnh',
        content: `
            <div class='tw-mt-2'>
            Nếu cơ thể gặp các bệnh lý như sốt, ốm, bạn sẽ cảm thấy mệt mỏi, mất tinh thần vì đây là một trong những nguyên nhân gây mất nước phổ biến. Vào những ngày không may bị bệnh, bạn hãy chăm chỉ uống nước hơn để hạn chế sự phát triển của các vi khuẩn, virus gây bệnh.
            </div>
          `,
      },
      {
        id: 607,
        title: '7. Uống nước khi cơ thể mệt mỏi, thiếu tập trung',
        content: `
            <div class='tw-mt-2'>
            Đôi khi những trạng thái uể oải, mệt mỏi, không thể tập trung làm bất kỳ điều gì lại đến từ việc: Bạn chưa nạp đủ cho cơ thể lượng nước mà nó cần! Một ly nước vào thời điểm đó sẽ là công thức giúp não bộ tỉnh táo và giúp bạn lấy lại năng lượng, tinh thần nhanh nhất.
            </div>
          `,
      },
      {
        id: 608,
        title: '',
        content: `
          <div class='tw-mt-2 tw-text-lg font-weight-700'>
            Thời điểm nào không nên uống nước?
          </div>
          <ul class='font-weight-400 tw-mt-2'>
            Mặc dù không thể phủ nhận những lợi ích tuyệt vời của nước, nhưng cũng có lúc cơ thể chúng ta nên học cách “từ chối tiếp nhận” và dừng việc uống nước để bảo vệ sức khỏe.

            <li class='tw-ml-5'>Không nên uống nhiều nước khi vừa ăn no vì nước sẽ làm loãng dịch vị dạ dày và các enzym tiêu hóa, gây ra căng tức, đầy bụng</li>
            <li class='tw-ml-5'>Không nên uống nước thêm khi cơ thể đã đủ lượng nước cần. Bạn có thể quan sát màu nước tiểu. Khi thấy nước tiểu có màu trong như nước lọc cũng là lúc cơ thể bạn đã nạp nước đủ</li>
            <li class='tw-ml-5'>Không nên uống nước sau khi ăn cay vì sẽ khiến cảm giác cay trở nên trầm trọng hơn</li>
            <li class='tw-ml-5'>Hạn chế uống quá nhiều nước trước khi đi ngủ vì hệ bài tiết lúc này sẽ rất mệt mỏi khi phải liên tục làm việc, giấc ngủ của bạn cũng theo đó mà bị gián đoạn</li>
            <li class='tw-ml-5'>Không uống quá nhiều nước trước và sau khi vận động vì sẽ khiến dạ dày khó chịu, ảnh hưởng đến tim mạch</li>
            <li class='tw-ml-5'>Trong lúc ăn, bạn cũng nên bỏ thói quen uống nước vì nó khiến thể tích dạ dày tăng lên, hệ tiêu hóa của bạn cũng phải làm việc vất vả hơn</li>
          </ul>

          <div class='tw-mt-2'>
            Trên đây là một số chia sẻ với chủ đề Nên và không nên uống nước trong thời điểm nào? Tiếp tục theo dõi những bài viết sau để có thêm nhiều kiến thức về uống nước đúng cách nhé!
          </div>
          `,
      },
    ],
  },
  {
    id: 7,
    contentLeft,
    title: 'Tại sao cần uống đủ nước khi làm việc?',
    contentRight,
    answers: [
      {
        id: 700,
        title: '',
        content: `
          <div class='tw-mt-2'>
            Ngày hôm nay bạn đã uống đủ nước tại nơi làm việc? Cung cấp đủ nước cho cơ thể sẽ là một trong những cách đơn giản mà hiệu quả, giúp bạn luôn giữ được tinh thần tỉnh táo để hoàn thành mọi nhiệm vụ.
          </div>

          <div>
            <div class='font-weight-700 tw-mt-2 tw-text-lg'>
              Mất nước ảnh hưởng như thế nào đến năng suất làm việc của dân công sở?
            </div>
            <div class='tw-mt-2'>
              Khi có quá nhiều công việc cần giải quyết, điều đầu tiên mà hầu hết các nhân viên nghĩ tới là một tách trà hay một ly cà phê để giữ sự tỉnh táo. Tuy nhiên, những loại thức uống này cũng mang đến không ít những tác động không tốt cho sức khỏe như: Làm tăng nhu cầu tiểu tiện, làm các chất thải đào thải nhanh ra ngoài và khiến cơ thể rơi vào trạng thái mất nước.
            </div>
            <div class='tw-mt-2'>
            Hãy dừng lại, khi bạn cảm thấy mình có dấu hiệu đau lưng, đau đầu, khô da, mỏi và khô mắt. Từ việc ở quá lâu trong phòng kín, thời gian dài tiếp xúc với bức xạ của các thiết bị điện tử, những công việc bàn giấy tưởng chừng như đơn giản nhưng cũng khiến cơ thể chúng ta bị “rút cạn” nếu không có biện pháp chăm sóc đúng cách và kịp thời.
            </div>
            <div class='tw-mt-2'>
            Nếu bạn cảm thấy đau đầu, mệt mỏi, uể oải không có tâm trạng để làm việc hay trì trệ trong suy nghĩ, giảm khả năng tập trung, hãy thử sốc lại tinh thần bằng một ly nước trước khi nghĩ tới một giải pháp khác.
            </div>
            <div class='tw-mt-2'>
            Bởi những dấu hiệu trên có thể đến từ việc cơ thể chưa được đáp ứng đủ lượng nước.
            Khả năng tập trung kém hay mỏi mệt cũng sẽ là một phần gián tiếp gây ảnh hưởng đến hiệu suất công việc và không khí làm việc của văn phòng.
            </div>
          </div>

          <div>
            <div class='font-weight-700 tw-mt-2 tw-text-lg'>
              Tầm quan trọng của việc cung cấp đủ nước đối với dân văn phòng
            </div>
            <ul class='font-weight-400 tw-mt-2'>
            Theo thống kê, một người làm công việc văn phòng hành chính sẽ bỏ ra 90.000 giờ trong đời để ngồi tại phòng làm việc. Chính vì thế, cung cấp đủ lượng nước hàng ngày cho cơ thể sẽ giúp bạn loại bỏ đi rất nhiều ảnh hưởng tiêu cực từ việc ngồi lâu và vận động trí não liên tục:
              <li class='tw-ml-5'>Nước hỗ trợ các khớp xương được bôi trơn, giúp lưu thông máu đều đến các cơ bắp. Những người chăm uống nước sẽ ít gặp các tình trạng đau lưng, mệt mỏi hoặc mất tập trung.</li>
              <li class='tw-ml-5'>Khi bạn cung cấp đủ lượng nước cho cơ thể, máu sẽ không bị đông đặc. Từ đó quá trình vận chuyển máu lên não diễn ra đều đặn và dễ dàng, các nơron thần kinh hoạt động tốt hơn, khả năng tập trung của bạn cho công việc sẽ được cải thiện.</li>
              <li class='tw-ml-5'>Tình trạng khô và mỏi mắt khi nhìn máy tính quá nhiều sẽ không thể được giải quyết triệt để nếu chỉ dựa vào các loại thuốc dưỡng hay nhỏ mắt. Bạn hãy tác động từ bên trong bằng cách bổ sung nước ngay khi thấy mắt có dấu hiệu mỏi hoặc khó chịu.</li>
              <li class='tw-ml-5'>Một lợi ích có thể dễ dàng nhìn thấy khi một nhân viên văn phòng bổ sung nước đủ và thường xuyên, đó là sự thể hiện qua làn da. Uống nước sẽ hạn chế khô da, giúp da được cung cấp đủ độ ẩm tự nhiên và luôn tươi tắn, căng bóng.</li>
            </ul>
          </div>

          <div class='tw-mt-2'>
            Để duy trì tốt thói quen uống đủ nước tại nơi làm việc, bạn có thể sắm cho mình một bình nước để sẵn trên bàn làm việc và ưu tiên cầm theo bình nước khi đi họp hay di chuyển địa điểm ngồi làm việc.
          </div>

          <div class='tw-mt-2'>
          Bên cạnh đó, thay vì ăn các loại ăn vặt với mức calo “khủng” nhưng lại không mang lại bất kỳ giá trị dinh dưỡng nào, bạn có thể lựa chọn bổ sung trái cây chứa nhiều nước như dưa, táo, cam, dừa để thay thế.
          </div>

          <div class='tw-mt-2'>
          Khi được nạp đủ nước, cơ thể bạn sẽ được duy trì hoạt động ở mức tối ưu và trong trạng thái sẵn sàng cho quá trình làm việc, đồng thời công việc luôn đạt hiệu quả cao.
          </div>
        `,
      },
    ],
  },
  {
    id: 8,
    contentLeft,
    title: 'Vì sao chúng ta nên uống nhiều nước hơn vào mùa đông',
    contentRight,
    answers: [
      {
        id: 800,
        title: '',
        content: `
          <div class='tw-mt-2'>
            Vào mùa đông, thời tiết lạnh khiến quá trình đổ mồ hôi diễn ra ít hơn, do đó nhu cầu uống nước của mọi người cũng giảm dần. Tuy nhiên theo các chuyên gia dinh dưỡng thời tiết hanh khô khiến cơ thể mất đi khoảng 0,6 - 0,9 lít nước ở niêm mạc hầu họng, đường hô hấp, mắt và da.
          </div>
          <div class='tw-mt-2'>
          Đặc biệt, thiếu nước vào mùa đông còn là điều kiện thuận lợi cho sự phát triển của virus gây bệnh bởi hệ miễn dịch sẽ trở nên suy yếu. Vì thế bạn cần chú trọng bổ sung đủ nước kể cả trong mùa đông.
          </div>

          <div class='font-weight-700 tw-mt-2 tw-text-lg'>
            Những lợi ích khi bạn bổ sung nước đủ vào mùa đông:
          </div>

          <div>
            <div class='font-weight-700 tw-mt-2'>
              Giữ ấm cho cơ thể
            </div>
            <div>
              Khi trời trở lạnh, cơ thể rất dễ gặp tình trạng rối loạn điều hòa nhiệt. Các yếu tố như hoạt động thể chất, hô hấp, nhiệt độ quá thấp và căng thẳng tinh thần diễn ra vào mùa đông đều gây ra mất nước. Uống đủ nước, đặc biệt là nước ấm giúp bạn duy trì mức nhiệt độ ở trạng thái bình thường, từ đó giữ ấm tốt hơn.
            </div>
          </div>

          <div>
            <div class='font-weight-700 tw-mt-2'>
              Tăng cường hệ miễn dịch
            </div>
            <div>
            Bổ sung nước vào mùa đông hỗ trợ tăng cường hệ miễn dịch, thúc đẩy các cơ quan trong cơ thể vận hành trơn tru, các phản ứng hóa học và vận chuyển các chất dinh dưỡng diễn ra theo đúng trình tự bình thường. Trong trường hợp các chức năng hoạt động tốt, hệ miễn dịch cũng được tăng lên bảo vệ bạn trước những tác nhân gây bệnh.
            </div>
          </div>

          <div>
            <div class='font-weight-700 tw-mt-2'>
              Cung cấp đủ độ ẩm cho da
            </div>
            <div>
            Không khí lạnh và nhiệt độ của mùa đông gây khô da, bong tróc và nứt nẻ, đây là tình trạng cơ thể thoát nước qua da. Vì vậy để ngăn ngừa tình trạng này, bạn hãy bổ sung nước đủ hàng ngày để làn da được bù lại lượng nước đã mất và giữ được sự tươi trẻ, khỏe mạnh.
            </div>
          </div>

          <div>
            <div class='font-weight-700 tw-mt-2'>
              Hạn chế các vấn đề về hô hấp
            </div>
            <div>
            Tình trạng khó thở vào mùa đông không phải là một hiện tượng ít gặp. Trời lạnh làm giảm nhiệt độ cơ thể, gây thu hẹp các mạch máu trong đường hô hấp và tăng nguy cơ nhiễm trùng đường hô hấp. Tác dụng của nước là tạo ra áp lực bên trong các mạch máu và duy trì hình dạng của nó, giúp máu lưu thông tốt hơn, ngăn ngừa các hiện tượng khó thở hay nguy cơ nhiễm trùng đường hô hấp ở chúng ta.
            </div>
          </div>

          <div>
            <div class='font-weight-700 tw-mt-2'>
              Ngăn ngừa tăng cân
            </div>
            <div>
            Uống nước khiến bạn cảm thấy no nhanh và hạn chế nạp quá nhiều thức ăn vào cơ thể. Hơn nữa bổ sung nước cũng là một cách làm tăng tốc độ trao đổi chất bằng cách phân giải lipid để giải phóng axit béo, giúp bạn giảm lượng calo nạp vào, thậm chí thúc đẩy quá trình giảm cân hiệu quả.
            </div>
          </div>

          <ul class='font-weight-400 tw-mt-2'>
            <div class='font-weight-700 tw-text-lg'>Nên bổ sung nước như thế nào vào mùa đông?</div>
              <li class='tw-ml-5'>Vào những ngày lạnh, bạn nên ưu tiên bổ sung nước ấm cho cơ thể</li>
              <li class='tw-ml-5'>Nên uống nước chậm và chia nhỏ thời gian uống nước</li>
              <li class='tw-ml-5'>Không nên uống quá nhiều nước vào trước hoặc sau bữa ăn hay thời điểm ban đêm trước khi đi ngủ</li>
            </ul>
          <div class='tw-mt-2'>
            Mùa đông này, đừng quên bổ sung đủ nước để có một cơ thể với hàng rào miễn dịch khỏe mạnh nhé!
          </div>
        `,
      },
    ],
  },
  {
    id: 9,
    contentLeft,
    title: 'Uống nước đúng cách để có sức khỏe tốt',
    contentRight,
    answers: [
      {
        id: 900,
        title: '',
        content: `
        <p class=''>
          Nước cần thiết cho sự hấp thụ dinh dưỡng và duy trì sự sống, nếu như thiếu nước các tế bào của chúng ta không thể tồn tại và phát triển. Vì thế việc bổ sung nước mỗi ngày là nhiệm vụ không thể thiếu. Vậy làm thế nào để việc uống nước mỗi ngày trở nên đúng cách và có lợi nhất cho cơ thể?
        </p>
`,
      },
      {
        id: 901,
        title: '1. Điều chỉnh tư thế uống nước: Nên ngồi để uống thay vì đứng',
        content: `
        <div class='tw-mt-2'>
          Việc đứng để uống nước sẽ vô tình làm phá vỡ sự cân bằng chất lỏng cần được cân bằng trong cơ thể. Nếu bạn có thói quen đứng để nạp nước trong thời gian dài, lượng nước phần lớn sẽ bị tích tụ tại bụng, khớp dẫn đến viêm khớp, nhức mỏi.
Tư thế uống nước cũng đóng vai trò vô cùng quan trọng, khi bạn ngồi để uống hệ thần kinh và các cơ sẽ cảm thấy thư giãn và thoải mái, làm tăng hiệu quả đào thải chất bẩn của thận.
        </div>
`,
      },
      {
        id: 902,
        title: '2. Lưu ý thời điểm uống nước phù hợp',
        content: `
        <ul class='font-weight-400 tw-mt-2'>
        Để lượng nước nạp vào phát huy tối đa tác dụng, việc lên kế hoạch uống nước theo thời gian biểu và thực hiện nó như một thói quen là điều cần thiết. Bạn có thể tham khảo các khung thời gian uống nước sau để uống nước đúng cách mỗi ngày:
          <li class='tw-ml-5'>Khi vừa thức dậy: 6h-7h sáng</li>
          <li class='tw-ml-5'>Sau khi ăn sáng và trước giờ làm: 8h-9h</li>
          <li class='tw-ml-5'>Trong khi làm việc ca sáng: 11h-12h trưa</li>
          <li class='tw-ml-5'>Sau bữa trưa và giấc ngủ trưa ngắn: 13h-14h</li>
          <li class='tw-ml-5'>Trong khi làm việc ca chiều: 15h-16h</li>
          <li class='tw-ml-5'>Trước khi tan làm: 17h</li>
          <li class='tw-ml-5'>Trước bữa ăn tối: 19h-20h</li>
          <li class='tw-ml-5'>Trước khi đi ngủ 30 phút đến 1 giờ: 22h - 22h30</li>
        </ul>
`,
      },
      {
        id: 903,
        title: '3. Uống như thế nào và bao nhiêu là đủ?',
        content: `
        <ul class='font-weight-400 tw-mt-2'>
        Mặc dù nước đem đến nhiều lợi ích cho sức khỏe nhưng bạn nên lưu ý không nên uống quá nhiều trong ngày thay vì đó hãy uống đủ.
          <li class='tw-ml-5'>Việc dung nạp một lượng nước quá lớn tại một thời điểm sẽ khiến bạn cảm thấy đầy hơi, khó chịu. Bạn có thể chia nhỏ số lần uống nước, mỗi lần uống thành nhiều ngụm nhỏ theo quy trình nuốt, thở và lặp lại tại nhiều thời điểm trong ngày.</li>
          <li class='tw-ml-5'>Một điều nữa mà mọi người thường thực hiện sai trong quá trình uống nước, đó là chọn nước đá lạnh để làm xoa dịu cơ thể khi đang cảm thấy khát. Nước lạnh làm xáo trộn quá trình tiêu hóa diễn ra trong dạ dày và làm giảm việc cung cấp máu cho các cơ quan khác nhau của cơ thể. Trong khi đó, nước ấm lại mang đến những phản ứng tích cực ngược lại, nó giúp quá trình tiêu hóa và trao đổi chất diễn ra tốt hơn.</li>
          <li class='tw-ml-5'>Trong thời điểm các bữa ăn chính trong ngày, bạn cũng nên hạn chế uống quá nhiều nước trước và sau ăn để dạ dày và các cơ quan tiêu hóa của bạn “dễ thở” hơn.</li>
        </ul>
`,
      },
      {
        id: 904,
        title: '4. Lắng nghe cơ thể uống nước đúng cách',
        content: `
        <ul class='font-weight-400 tw-mt-2'>
        Như vậy, tùy vào nhu cầu cơ thể dựa trên cân nặng, chiều cao, cường độ vận động, khí hậu mà chúng ta sẽ tính toán lượng nước nạp vào cho phù hợp vì không phải ai cũng sẽ cần nạp và tiêu thụ chung một lượng nước giống nhau.
          <li class='tw-ml-5'>Những người nạp đúng và đủ lượng nước cần sẽ luôn giữ được trạng thái tỉnh táo, không cảm thấy khô họng, khô da hay khát nước, quá trình đi tiểu diễn ra đều đặn sau 2-4 tiếng với tần suất 6-7 lần/ngày.</li>
          <li class='tw-ml-5'>Trong đó, khi cơ thể đang thiếu nước, bạn sẽ thấy khát cổ, khô họng, da thiếu độ ẩm, dễ nổi mụn và tiêu hóa kém hơn, tần suất đi tiểu ít chỉ từ 3-4 lần/ngày.</li>
          <li class='tw-ml-5'>Trái lại, một người uống quá nhiều nước sẽ rơi vào những tình trạng như buồn nôn, phù nề tay chân, môi hoặc dễ chuột rút, tần suất đi tiểu liên tục.</li>
        </ul>

        <div class='tw-mt-2'>
          Căn cứ vào những dấu hiệu trên, bạn có thể tự theo dõi sự thay đổi trong cơ thể và điều chỉnh thói quen uống nước của mình sao cho phù hợp. Hãy quan sát và cảm nhận hiệu quả từ việc duy trì uống nước đúng cách, chắc chắn kết quả mang lại sẽ vô cùng bất ngờ đó!
        </div>
`,
      },
    ],
  },

  {
    id: 10,
    contentLeft,
    title: '5 Cách đơn giản giúp bạn uống nhiều nước hơn',
    contentRight,
    answers: [
      {
        id: 1000,
        title: '',
        content: `
        <p class=''>
        Mặc dù mọi người đều biết đến lợi ích của nước đối với sức khỏe, thế nhưng đôi khi chúng ta vẫn bỏ qua nó chỉ vì một chữ “lười”. Tuy nhiên, một số mẹo sau đây sẽ giúp bạn chinh phục được mục tiêu đạt đủ KPI khối lượng nước cần nạp mỗi ngày.
        </p>
`,
      },
      {
        id: 1001,
        title: '',
        content: `
        <div class='tw-mt-2'>
          <div class='font-weight-700'>
            Đặt một bình nước có vạch đo ngay trên bàn làm việc
          </div>
          <div class='tw-mt-2'>
            Có những người uống ít nước đơn giản vì quên và ngại đi lấy nước nhiều lần trong ngày. Sở hữu một bình nước ngay trên bàn làm việc sẽ giúp bạn tiết kiệm thời gian di chuyển tới điểm lấy nước và là một cách đơn giản giúp bạn bổ sung nước nhiều hơn.
          </div>
          <div class='tw-mt-2'>
          Bạn có thể uống dễ dàng ngay cả khi đầu óc và cơ thể đang quá bận để xử lý các nhiệm vụ. Bình nước như một lời nhắc nhở trực quan, giúp bạn ý thức được việc uống nước và mức vạch đo được chia sẵn giúp bạn định lượng tốt hơn lượng nước nạp vào.
          </div>
        </div>

        <div class='tw-mt-2'>
          <div class='font-weight-700'>
            Tạo thói quen uống nước trước các bữa ăn
          </div>
          <div class='tw-mt-2'>
          Một cách đơn giản khác để tăng lượng nước nạp vào đó là tạo thói quen uống một ly nước trước các bữa ăn chính. Thực hiện trong một thời gian dài, bộ não bạn sẽ thiết lập chế độ tự động uống trước khi ăn từ 30 phút đến 1 tiếng. Điều này không chỉ góp phần giúp bạn nạp thêm nước mà còn giảm lượng thức ăn nạp vào, tránh trường hợp bạn ăn quá nhiều mà không có sự kiểm soát.
          </div>
        </div>
        <div class='tw-mt-2'>
          <div class='font-weight-700'>
            Ăn các loại thực phẩm chứa nhiều nước
          </div>
          <div class='tw-mt-2'>
          Nếu việc uống nước tinh khiết không khiến bạn cảm thấy hứng thú, bạn có thể tăng hương vị cho nước khi uống hoặc đôi khi thay thế bằng các loại nước ép trái cây. Nước ép trái cây là một lựa chọn tốt cho sức khỏe nhưng bạn chỉ nên lựa chọn để bổ sung kết hợp, thay vì thay thế hoàn toàn nước lọc.
          </div>
          <div class='tw-mt-2'>
          Ngoài ra, những loại trái cây phổ biến chứa nhiều nước như dưa hấu, dưa chuột, kiwi, bưởi, cam, dừa sẽ hỗ trợ bạn bổ sung nước khá hiệu quả.
          </div>
        </div>
        <div class='tw-mt-2'>
          <div class='font-weight-700'>
            Tạo nhắc nhở uống nước trên điện thoại di động
          </div>
          <div class='tw-mt-2'>
          Bạn có thể thiết lập các khung giờ uống nước trong ngày và chọn chế độ báo thức hoặc nhắc nhở trên di động để tránh quên. Dựa vào đó, lượng nước nạp vào cơ thể sẽ được duy trì hàng ngày.
          </div>
        </div>
        <div class='tw-mt-2'>
          <div class='font-weight-700'>
          Theo dõi lịch uống nước trên các ứng dụng thông minh như HappyTime
          </div>
          <div class='tw-mt-2'>
          Mặc dù hình thức tạo thông báo/ nhắc nhở trên điện thoại khá tiện lợi nhưng đôi khi bạn sẽ cảm thấy khó chịu mỗi khi thông báo đến.
          </div>
          <div class='tw-mt-2'>
          Chính vì thế, ứng dụng tính năng Watee nhắc lịch uống nước trên HappyTime sẽ là một hình thức nhắc nhở “dễ thương” giúp chúng ta, đặc biệt là những nhân viên văn phòng cảm thấy yêu thích việc uống nước hơn!
          </div>
          <div class='tw-mt-2'>
          Watee sẽ là người bạn “thân thiện” đồng hành chăm sóc sức khỏe cho dân văn phòng, tạo lập thói quen uống nước đúng giờ và đúng liều lượng.
          </div>
        </div>
`,
      },
    ],
  },
];
