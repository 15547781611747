const baseAsset = '/images/water-reminder';

export const iconMap: Record<string, string> = {
  pure: `${baseAsset}/pure.svg`,
  tea: `${baseAsset}/tea.svg`,
  soda: `${baseAsset}/soda.svg`,
  milk: `${baseAsset}/milk.svg`,
  juice: `${baseAsset}/juice.svg`,
  frappe: `${baseAsset}/frappe.svg`,
  coffee: `${baseAsset}/coffee.svg`,
  alcohol: `${baseAsset}/alcohol.svg`,
  cacao: `${baseAsset}/cacao.svg`,
  'milk-tea': `${baseAsset}/milk-tea.svg`,
  beer: `${baseAsset}/beer.svg`,
  cocktail: `${baseAsset}/cocktail.svg`,
  'coconut-water': `${baseAsset}/coconut-water.svg`,
  'lemon-water': `${baseAsset}/lemon-water.svg`,
  'mineral-water': `${baseAsset}/mineral-water.svg`,
  'power-water': `${baseAsset}/power-water.svg`,
  'strong-alcohol': `${baseAsset}/strong-alcohol.svg`,
  wine: `${baseAsset}/wine.svg`,
};
