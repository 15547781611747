import { NavigateOptions, Navigator } from '@topcv/react-router-map';

export const Nav = {
  push(routeName: string, options?: NavigateOptions) {
    Navigator.push(routeName, options);
  },
  replace(routeName: string, options?: NavigateOptions) {
    Navigator.replace(routeName, options);
  },
  pop(routeName: string, options?: NavigateOptions) {
    Navigator.push(routeName, {
      ...options,
      state: {
        ...options?.state,
        directAction: 'POP',
      },
    });
  },
  back() {
    Navigator.goBack();
  },
};
