export const weightOptions = (): any[] => {
  const options = [];
  for (let i = 20; i <= 500; i++) {
    options.push({
      label: String(i),
      value: String(i),
    });
  }
  return [options];
};
