export const RouteNames = {
  ONBOARDING_HOME: 'onboarding-home',
  ONBOARDING_GENDER: 'onboarding-gender',
  ONBOARDING_WEIGHT: 'onboarding-weight',
  ONBOARDING_SETTING: 'onboarding-setting',

  APP_HOME: 'app-home',
  APP_DRINK: 'app-drink',
  APP_DRINK_INTRO: 'app-drink-intro',
  APP_SETTING: 'app-setting',
  APP_HISTORY: 'app-history',
  APP_HISTORY_DETAIL: 'app-history-detail',
  APP_HANDBOOK: 'app-handbook',
  APP_HANDBOOK_DETAIL: 'app-handbook-detail',
};
