import React, { useCallback, useEffect, useState } from 'react';
import HappyMini from '@happymini/core';
import { RouteNames } from 'src/constants/routeNames';
import employee from 'src/api/employee';
import { Nav } from '..';
import { useSetStoreState } from 'src/store';
import './style.scss';
import SafeAreaLayout from 'src/layouts/SafeAreaLayout';
import { AutoCenter, SpinLoading } from '@happymini/react-ui';
import { useLocation } from 'react-router';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CheckSettingState = () => {
  const setStore = useSetStoreState();
  const [loading, setLoading] = useState(true);
  const query = useQuery();

  const checkEmployeeSetting = useCallback(async () => {
    setLoading(true);
    const data = await employee.getEmployeeSetting();
    setLoading(false);
    if (!data.success) {
      return Nav.replace(RouteNames.ONBOARDING_GENDER, {
        state: {
          directAction: 'NONE',
        },
      });
    }
    setStore('employeeSetting', data.data);
    let routeName = RouteNames.APP_HOME;
    if (query.get('redirect_page')) {
      const redirectPageName = query.get('redirect_page') as keyof typeof RouteNames;
      if (Object.keys(RouteNames).includes(redirectPageName)) {
        routeName = RouteNames[redirectPageName];
      }
    }
    Nav.replace(routeName, {
      state: {
        directAction: 'NONE',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    HappyMini.StatusBar.setBarStyle('light-content');
    checkEmployeeSetting();
  }, [checkEmployeeSetting]);

  return (
    <>
      <section className={'section section-bg onboarding'}>
        <SafeAreaLayout className='section-container'>
          <img
            onClick={() => HappyMini.App.close()}
            className='onboarding-btn-close'
            src='/images/onboarding/close.svg'
            alt=''
            width={30}
          />
          <div className='onboarding-title tw-text-center'>
            <img width={150} src='/images/logo.png' />
          </div>
          <div className='onboarding-logo tw-text-center'>
            <span className='color-primary fs-20 onboarding-logo-percentage'>100%</span>
            <img className='min-w-[256px] min-h-[326px]' src='/images/onboarding/droplets.png' alt='' />
          </div>
          {loading && (
            <AutoCenter className='tw-mt-[20px]'>
              <SpinLoading style={{ '--size': '50px' }} color='#fff' />
            </AutoCenter>
          )}
        </SafeAreaLayout>
      </section>
    </>
  );
};

export default CheckSettingState;
