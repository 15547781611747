import { useQuery, UseQueryOptions } from 'react-query';
import drinkWater from 'src/api/drink-water';
import { Response } from 'src/typings/axios';
import { DrinkInfo } from 'src/typings/water';

type FetchDrinkInfoPayloadType = {
  date: string;
  config?: UseQueryOptions<Response<{ data: DrinkInfo }>>;
};

export const fetchDrinkInfo = (payload: FetchDrinkInfoPayloadType) => {
  return {
    queryKey: ['drink-info', payload.date],
    queryFn: async () => await drinkWater.getHistoryByDate(payload.date),
    ...payload?.config,
  };
};

export const useFetchDrinkInfo = (payload: FetchDrinkInfoPayloadType) => {
  return useQuery<Response<{ data: DrinkInfo }>>(fetchDrinkInfo(payload));
};
