import React from 'react';
import { Button } from '@happymini/react-ui';
import { Nav } from 'src/router';

const ButtonNavigate = (props: { namePath: string; onContinue?: () => void }) => {
  return (
    <div className='hp-button'>
      <Button
        onClick={() => {
          Nav.push(props.namePath);
          props.onContinue && props.onContinue();
        }}
      >
        Tiếp tục
      </Button>
    </div>
  );
};

export default ButtonNavigate;
