import React from 'react';
import { Nav } from 'src/router';

const BackButton = (props: { pathName?: string; title?: string }) => {
  const { pathName, title } = props;

  return (
    <>
      <div
        onClick={() => {
          Nav.back();
        }}
        className='tw-flex tw-mb-8 tw-items-center tw-justify-between tw-bg-red'
      >
        <div>
          <img src='/images/back.svg' alt='' />
        </div>
        <div className='fs-20 font-weight-600 color-primary'>{title}</div>
        <div className='tw-w-[20px]'></div>
      </div>
    </>
  );
};

export default BackButton;
